import { __values as n, __spread as t, __awaiter as r, __generator as e } from "tslib";
var i = function () {
    var r = this;
    this.type = "sync", this.fns = new Set(), this.on = function (n) {
      r.fns.add(n);
    }, this.call = function () {
      for (var e, i, s = [], a = 0; a < arguments.length; a++) s[a] = arguments[a];
      try {
        for (var o = n(r.fns), l = o.next(); !l.done; l = o.next()) {
          var c = l.value;
          c.apply(void 0, t(s));
        }
      } catch (f) {
        e = {
          error: f
        };
      } finally {
        try {
          l && !l.done && (i = o.return) && i.call(o);
        } finally {
          if (e) throw e.error;
        }
      }
    };
  },
  s = function () {
    var i = this;
    this.type = "async", this.fns = new Set(), this.on = function (n) {
      i.fns.add(n);
    }, this.call = function () {
      for (var s = [], a = 0; a < arguments.length; a++) s[a] = arguments[a];
      return r(i, void 0, void 0, function () {
        var r, i, a, o, l, c;
        return e(this, function (e) {
          switch (e.label) {
            case 0:
              r = [];
              try {
                for (i = n(this.fns), a = i.next(); !a.done; a = i.next()) o = a.value, r.push(o.apply(void 0, t(s)));
              } catch (f) {
                l = {
                  error: f
                };
              } finally {
                try {
                  a && !a.done && (c = i.return) && c.call(i);
                } finally {
                  if (l) throw l.error;
                }
              }
              return [4, Promise.all(r)];
            case 1:
              return e.sent(), [2];
          }
        });
      });
    };
  },
  a = function () {
    var i = this;
    this.type = "asyncSeries", this.fns = new Set(), this.on = function (n) {
      i.fns.add(n);
    }, this.call = function () {
      for (var s = [], a = 0; a < arguments.length; a++) s[a] = arguments[a];
      return r(i, void 0, void 0, function () {
        var r, i, a, o, l;
        return e(this, function (e) {
          switch (e.label) {
            case 0:
              e.trys.push([0, 5, 6, 7]), r = n(this.fns), i = r.next(), e.label = 1;
            case 1:
              return i.done ? [3, 4] : [4, i.value.apply(void 0, t(s))];
            case 2:
              e.sent(), e.label = 3;
            case 3:
              return i = r.next(), [3, 1];
            case 4:
              return [3, 7];
            case 5:
              return a = e.sent(), o = {
                error: a
              }, [3, 7];
            case 6:
              try {
                i && !i.done && (l = r.return) && l.call(r);
              } finally {
                if (o) throw o.error;
              }
              return [7];
            case 7:
              return [2];
          }
        });
      });
    };
  };
export { s as AsyncHook, a as AsyncSeriesHook, i as SyncHook };