var o;
function i() {
  if (!o) {
    var i = window.navigator.userAgent.toLowerCase();
    o = /iphone|ipad|ipod/.test(i) ? "ios" : /android/.test(i) ? "android" : "web";
  }
  return o;
}
function n() {
  o = void 0;
}
export { n as _cleanupCachedOS, i as getOS };