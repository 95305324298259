import { __awaiter as t, __generator as e } from "tslib";
import { CREATE_SUBWINDOW_FAILED as i, EXCEPTION_IN_SUBWINDOW as r, UNAUTHORIZED as o, INVALID_ARGUMENT as n, MAX_NUM_OF_SEND_MESSAGES as s, INVALID_CONFIG as a, FORBIDDEN as l } from "@liff/consts";
import { qs as h, getOriginOfUrl as u, isIpad as c, createLiffError as f, LiffError as p, compareVersion as d } from "@liff/util";
import { getConfig as w, getContext as g } from "@liff/store";
import { isInClient as m } from "@liff/is-in-client";
import { isLoggedIn as b } from "@liff/is-logged-in";
import { sendShareTargetPicker as v } from "@liff/analytics";
import { isSubWindow as y } from "@liff/is-sub-window";
import { getLineVersion as T } from "@liff/get-line-version";
import { logger as k } from "@liff/logger";
import { getEndPoint as P, fetch as C } from "@liff/server-api";
import { getOS as S } from "@liff/get-os";
import { messageReceive as I, messageTell as W, removeListen as A } from "@liff/window-postmessage";
var E = function () {
    function r() {
      this.payloadToShareTargetPicker = null, this.popupWindow = null, this.doesWaitForSubwindowResult = !1;
    }
    return r.getInstance = function () {
      return r.instance ? r.instance.reset() : r.instance = new r(), r.instance;
    }, r.prototype.init = function (i) {
      return t(this, void 0, void 0, function () {
        var t, r;
        return e(this, function (e) {
          switch (e.label) {
            case 0:
              return e.trys.push([0, 5,, 6]), this.liffId = i.referrer.liffId, this.doesWaitForSubwindowResult = !(!i.options || !i.options.waitForSubwindowResult), this.allowPostMessageOrigin = this.initAllowPostMessageOrigin(), this.payloadToShareTargetPicker = this.buildPayloadToShareTargetPicker(i), window.AbortController && (this.abortController = new window.AbortController()), this.prepareAnotherWindow(), [4, this.initOtt()];
            case 1:
              return e.sent(), this.initListener(), this.openAnotherWindow(), this.doesWaitForSubwindowResult ? [4, this.pollingShareResult()] : [3, 3];
            case 2:
              return t = e.sent(), this.finalize(), [2, t];
            case 3:
              return [2];
            case 4:
              return [3, 6];
            case 5:
              if (r = e.sent(), this.finalize(), "AbortError" !== r.name) throw r;
              return [3, 6];
            case 6:
              return [2];
          }
        });
      });
    }, r.prototype.resetAllVariables = function () {
      this.liffId = "", this.allowPostMessageOrigin = "", this.payloadToShareTargetPicker = null, this.ott = "", this.popupWindow = null, this.timeoutIDForHealthCheck = null, this.abortController = null, this.internalError = null, this.doesWaitForSubwindowResult = !1;
    }, r.prototype.reset = function () {
      this.finalize(), this.resetAllVariables();
    }, r.prototype.finalize = function () {
      var t, e;
      this.abortController && this.abortController.abort(), m() || (t = this.timeoutIDForHealthCheck, e = this.popupWindow, A(window, "message.receivedHealthcheck"), t && clearTimeout(t), e && !e.closed && e.close());
    }, r.prototype.buildPayloadToShareTargetPicker = function (t) {
      return {
        messages: t.messages,
        isMultiple: t.isMultiple,
        referrer: t.referrer
      };
    }, r.prototype.initAllowPostMessageOrigin = function (t) {
      return void 0 === t && (t = P("shareTargetPicker")), u(t);
    }, r.prototype.initOtt = function () {
      return t(this, void 0, void 0, function () {
        var t, i, r;
        return e(this, function (e) {
          switch (e.label) {
            case 0:
              return this.abortController && (t = this.abortController.signal), i = P("shareTargetPickerOtt") + "/" + this.liffId + "/ott", r = this, [4, C(i, {
                method: "GET",
                signal: t
              }).then(function (t) {
                return t.ott;
              })];
            case 1:
              return r.ott = e.sent(), [2];
          }
        });
      });
    }, r.prototype.prepareAnotherWindow = function () {
      m() || ("ios" !== S() || c() ? this.popupWindow = window.open("", "liffpopup", "width=480, height=640, menubar=no, toolbar=no, scrollbars=yes") : this.popupWindow = window.open());
    }, r.prototype.openAnotherWindow = function () {
      if (m() && this.payloadToShareTargetPicker) t = this.liffId, e = this.ott, r = this.payloadToShareTargetPicker, o = {
        liffId: t,
        ott: e,
        data: JSON.stringify(r),
        closeModals: !1
      }, location.href = "line://picker?" + h.stringify(o);else {
        if (this.timeoutIDForHealthCheck = window.setTimeout(this.healthCheck.bind(this), 1e3), !this.popupWindow) throw f(i);
        !function (t, e, i) {
          var r = {
            liffId: e,
            ott: i
          };
          t.location.href = P("shareTargetPicker") + "?" + h.stringify(r);
        }(this.popupWindow, this.liffId, this.ott);
      }
      var t, e, r, o;
    }, r.prototype.initListener = function () {
      var t, e;
      m() || (t = this.onReceivedHealthcheck.bind(this), e = this.allowPostMessageOrigin, I(window, "receivedHealthcheck", t, e));
    }, r.prototype.healthCheck = function () {
      return t(this, void 0, void 0, function () {
        var t;
        return e(this, function (e) {
          switch (e.label) {
            case 0:
              if (this.popupWindow && !this.popupWindow.closed) return [3, 7];
              if (!this.doesWaitForSubwindowResult) return [3, 5];
              e.label = 1;
            case 1:
              return e.trys.push([1, 3,, 4]), [4, this.onCanceled()];
            case 2:
              return e.sent(), [3, 4];
            case 3:
              return t = e.sent(), this.internalError = t, [3, 4];
            case 4:
              return [3, 6];
            case 5:
              this.finalize(), e.label = 6;
            case 6:
              return [3, 8];
            case 7:
              i = this.popupWindow, r = this.allowPostMessageOrigin, W(i, "healthcheck", void 0, r), this.timeoutIDForHealthCheck = window.setTimeout(this.healthCheck.bind(this), 1e3), e.label = 8;
            case 8:
              return [2];
          }
          var i, r;
        });
      });
    }, r.prototype.onReceivedHealthcheck = function () {
      if (!this.popupWindow || !this.payloadToShareTargetPicker) throw f(i);
      var t, e, r;
      t = this.popupWindow, e = this.payloadToShareTargetPicker, r = this.allowPostMessageOrigin, W(t, "ready", e, r);
    }, r.prototype.onCanceled = function () {
      return t(this, void 0, void 0, function () {
        var t, i;
        return e(this, function (e) {
          switch (e.label) {
            case 0:
              if (m() || !this.ott) throw new Error("need to call with ott in client");
              return this.abortController && (t = this.abortController.signal), i = {
                liffId: this.liffId,
                ott: this.ott
              }, [4, C(P("shareTargetPickerResult") + "?" + h.stringify(i), {
                method: "POST",
                signal: t,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded"
                },
                body: "result=CANCEL"
              })];
            case 1:
              return [2, "ok" === e.sent().status];
          }
        });
      });
    }, r.prototype.getShareResult = function () {
      return t(this, void 0, void 0, function () {
        var t, i;
        return e(this, function (e) {
          if (!this.ott) throw new Error("need to call with ott in client");
          return this.abortController && (t = this.abortController.signal), i = {
            liffId: this.liffId,
            ott: this.ott
          }, k.debug("fetch: getShareResult"), [2, C(P("shareTargetPickerResult") + "?" + h.stringify(i), {
            method: "GET",
            headers: {
              Accept: "application/json"
            },
            signal: t
          })];
        });
      });
    }, r.isPollingTimeOut = function (t, e) {
      return (e - t) / 6e4 >= 10;
    }, r.prototype.pollingShareResult = function () {
      return t(this, void 0, void 0, function () {
        var t, i;
        return e(this, function (e) {
          switch (e.label) {
            case 0:
              t = Date.now(), e.label = 1;
            case 1:
              if (r.isPollingTimeOut(t, Date.now())) return [3, 4];
              if (this.internalError) throw this.internalError;
              return [4, this.getShareResult()];
            case 2:
              if ((i = e.sent()) && i.result) switch (i.result) {
                case "SUCCESS":
                  return [2, {
                    status: "success"
                  }];
                case "CANCEL":
                  return [2];
                case "FAILURE":
                default:
                  throw new Error(i.resultDescription);
              }
              return [4, new Promise(function (t) {
                setTimeout(t, 500);
              })];
            case 3:
              return e.sent(), [3, 1];
            case 4:
              throw new Error("Timeout: not finished within 10min");
          }
        });
      });
    }, r;
  }(),
  R = function () {
    function i() {
      var i = this;
      this.name = "shareTargetPicker", this.shareTargetPicker = function (l, h) {
        return void 0 === h && (h = {}), t(i, void 0, void 0, function () {
          var t, i, u, c, g, y, k;
          return e(this, function (e) {
            switch (e.label) {
              case 0:
                if (t = void 0 === h.isMultiple || h.isMultiple, this.checkPermission(), !b()) throw f(o, "Need access_token for api call, Please login first");
                if (!l || !Array.isArray(l) || 0 === l.length) throw f(n, "no proper argument");
                if (l.length > s) throw f(n, "exceed the limit of num of messages");
                if (!(i = w().liffId)) throw f(a);
                window.liff && (u = window.liff).analytics && v(u.analytics), e.label = 1;
              case 1:
                return e.trys.push([1, 3,, 4]), c = E.getInstance(), g = T(), y = {
                  waitForSubwindowResult: !0
                }, m() && g && d(g, "10.11.0") < 0 && (y.waitForSubwindowResult = !1), [4, c.init({
                  messages: l,
                  isMultiple: t,
                  referrer: {
                    liffId: i,
                    url: location.origin
                  },
                  options: y
                })];
              case 2:
                return [2, e.sent()];
              case 3:
                throw (k = e.sent()) instanceof p ? k : f(r, k.message);
              case 4:
                return [2];
            }
          });
        });
      }, this.checkPermission = function () {
        if (y()) throw f(l, "The operation is not allowed in the SubWindow");
        var t = ((g() || {}).availability || {}).shareTargetPicker || {},
          e = t.permission,
          i = t.minVer;
        if (!e) throw m() ? f(l, "Need LINE App " + i + " at least or consent on shareTargetPicker usage on LINE developer site") : f(l, "Need consent on shareTargetPicker usage on LINE developer site");
      };
    }
    return i.prototype.install = function () {
      return this.shareTargetPicker;
    }, i;
  }(),
  O = new R();
export { R as ShareTargetPickerModule, O as module };