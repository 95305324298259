!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define("liff", [], t) : "object" == typeof exports ? exports.liff = t() : e.liff = t();
}(window, function () {
  return function (e) {
    var t = {};
    function r(i) {
      if (t[i]) return t[i].exports;
      var n = t[i] = {
        i: i,
        l: !1,
        exports: {}
      };
      return e[i].call(n.exports, n, n.exports, r), n.l = !0, n.exports;
    }
    return r.m = e, r.c = t, r.d = function (e, t, i) {
      r.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: i
      });
    }, r.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, r.t = function (e, t) {
      if (1 & t && (e = r(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var i = Object.create(null);
      if (r.r(i), Object.defineProperty(i, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var n in e) r.d(i, n, function (t) {
        return e[t];
      }.bind(null, n));
      return i;
    }, r.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return r.d(t, "a", t), t;
    }, r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, r.p = "https://static.line-scdn.net/liff/edge/2/", r(r.s = 0);
  }([function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.liff = void 0, r(1), r(2);
    var i = r(3).default;
    t.liff = i, t.default = i;
  }, function (e, t) {
    e.exports = require("whatwg-fetch");
  }, function (e, t) {
    e.exports = require("promise-polyfill/src/polyfill");
  }, function (e, t, r) {
    "use strict";

    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var i = r(4),
      n = r(5),
      l = r(6),
      o = r(7),
      u = r(8),
      a = r(9),
      f = r(10),
      s = r(11),
      c = r(12),
      d = r(13),
      b = r(14),
      p = r(15),
      g = r(16),
      w = r(17),
      m = r(18),
      v = r(19),
      x = r(20),
      q = r(21),
      y = r(22),
      h = r(23),
      P = r(24),
      I = r(25),
      M = r(26),
      _ = r(27),
      j = r(28),
      L = r(29),
      O = Object.defineProperties({}, {
        getOS: {
          value: a.getOS,
          enumerable: !0,
          writable: !0
        },
        getVersion: {
          value: f.getVersion,
          enumerable: !0,
          writable: !0
        },
        getLanguage: {
          value: s.getLanguage,
          enumerable: !0,
          writable: !0
        },
        isInClient: {
          value: o.isInClient,
          enumerable: !0,
          writable: !0
        },
        isLoggedIn: {
          value: d.isLoggedIn,
          enumerable: !0,
          writable: !0
        },
        logout: {
          value: b.logout,
          enumerable: !0,
          writable: !0
        },
        getAccessToken: {
          value: l.getAccessToken,
          enumerable: !0,
          writable: !0
        },
        getIDToken: {
          value: l.getIDToken,
          enumerable: !0,
          writable: !0
        },
        getDecodedIDToken: {
          value: l.getDecodedIDToken,
          enumerable: !0,
          writable: !0
        },
        getContext: {
          value: l.getContext,
          enumerable: !0,
          writable: !0
        },
        openWindow: {
          value: m.openWindow,
          enumerable: !0,
          writable: !0
        },
        closeWindow: {
          value: v.closeWindow,
          enumerable: !0,
          writable: !0
        },
        getFriendship: {
          value: j.attachChecker(q.getFriendship, "profile"),
          enumerable: !0,
          writable: !0
        },
        getAId: {
          value: l.getAId,
          enumerable: !0,
          writable: !0
        },
        getProfilePlus: {
          value: l.getProfilePlus,
          enumerable: !0,
          writable: !0
        },
        getIsVideoAutoPlay: {
          value: l.getIsVideoAutoPlay,
          enumerable: !0,
          writable: !0
        },
        getLineVersion: {
          value: g.getLineVersion,
          enumerable: !0,
          writable: !0
        },
        isApiAvailable: {
          value: w.isApiAvailable,
          enumerable: !0,
          writable: !0
        },
        getProfile: {
          value: j.attachChecker(c.getProfile, "profile"),
          enumerable: !0,
          writable: !0
        },
        sendMessages: {
          value: j.attachChecker(x.sendMessages, "chat_message.write"),
          enumerable: !0,
          writable: !0
        },
        subWindow: {
          value: y.subWindow,
          enumerable: !0,
          writable: !0
        },
        ready: {
          value: u.ready,
          enumerable: !0,
          writable: !0
        },
        id: {
          get: function () {
            return l.getConfig().liffId || null;
          },
          enumerable: !0
        },
        _dispatchEvent: {
          value: p.dispatch,
          enumerable: !0,
          writable: !0
        },
        _call: {
          value: p.call,
          enumerable: !0,
          writable: !0
        },
        _addListener: {
          value: p.addListener,
          enumerable: !0,
          writable: !0
        },
        _removeListener: {
          value: p.removeListener,
          enumerable: !0,
          writable: !0
        },
        _postMessage: {
          value: p.postMessage,
          enumerable: !0,
          writable: !0
        }
      }),
      k = new h.ModuleDriverImpl(),
      A = new h.ModuleContextImpl(k, O),
      C = new h.UseModule(k, A, {
        namespacePrefix: ""
      }).install();
    var T = new h.LiffPluginContextImpl(k, O);
    [new h.UseModule(k, T), new n.LoginModule(), new i.InitModule(), new P.AnalyticsModule(), _.module, I.module, M.module, j.module, L.module].forEach(function (e) {
      C.call(O, e);
    }), t.default = O;
  }, function (e, t) {
    e.exports = require("@liff/init");
  }, function (e, t) {
    e.exports = require("@liff/login");
  }, function (e, t) {
    e.exports = require("@liff/store");
  }, function (e, t) {
    e.exports = require("@liff/is-in-client");
  }, function (e, t) {
    e.exports = require("@liff/ready");
  }, function (e, t) {
    e.exports = require("@liff/get-os");
  }, function (e, t) {
    e.exports = require("@liff/get-version");
  }, function (e, t) {
    e.exports = require("@liff/get-language");
  }, function (e, t) {
    e.exports = require("@liff/get-profile");
  }, function (e, t) {
    e.exports = require("@liff/is-logged-in");
  }, function (e, t) {
    e.exports = require("@liff/logout");
  }, function (e, t) {
    e.exports = require("@liff/native-bridge");
  }, function (e, t) {
    e.exports = require("@liff/get-line-version");
  }, function (e, t) {
    e.exports = require("@liff/is-api-available");
  }, function (e, t) {
    e.exports = require("@liff/open-window");
  }, function (e, t) {
    e.exports = require("@liff/close-window");
  }, function (e, t) {
    e.exports = require("@liff/send-messages");
  }, function (e, t) {
    e.exports = require("@liff/get-friendship");
  }, function (e, t) {
    e.exports = require("@liff/sub-window");
  }, function (e, t) {
    e.exports = require("@liff/use");
  }, function (e, t) {
    e.exports = require("@liff/analytics");
  }, function (e, t) {
    e.exports = require("@liff/permanent-link");
  }, function (e, t) {
    e.exports = require("@liff/is-sub-window");
  }, function (e, t) {
    e.exports = require("@liff/scan-code-v2");
  }, function (e, t) {
    e.exports = require("@liff/permission");
  }, function (e, t) {
    e.exports = require("@liff/share-target-picker");
  }]).default;
});