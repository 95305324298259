import { SUB_WINDOW_IDNTIFICATION_KEY as t, STORE_KEY as n, INVALID_CONFIG as i } from "@liff/consts";
import { createLiffError as e, isSubWindow as o } from "@liff/util";
import { getConfig as r } from "@liff/store";
import { isInClient as f } from "@liff/is-in-client";
var u = function () {
    function t() {}
    return t.prototype.invoke = function () {
      return o();
    }, t;
  }(),
  s = function () {
    function o(t) {
      this.storage = t;
    }
    return Object.defineProperty(o, "IN_SUB_WINDOW_KEY", {
      get: function () {
        return "inSubWindow";
      },
      enumerable: !1,
      configurable: !0
    }), o.prototype.invoke = function () {
      return !(!this.getInSubWindow() && !this.getSubWindowIdentifier()) || !!new URLSearchParams(window.location.search).has(t) && (this.setInSubWindow(!0), !0);
    }, o.prototype.getInSubWindow = function () {
      var t = this.storage.getItem(n + ":" + this.getLiffId() + ":" + o.IN_SUB_WINDOW_KEY);
      return null !== t && JSON.parse(t);
    }, o.prototype.getSubWindowIdentifier = function () {
      var t,
        n,
        i = "liff.subwindow.identifier",
        e = new URLSearchParams(window.location.search);
      return e.get(i) || (t = i, (n = e.get("liff.state")) ? new URLSearchParams(n).get(t) : null) || null;
    }, o.prototype.setInSubWindow = function (t) {
      this.storage.setItem(n + ":" + this.getLiffId() + ":" + o.IN_SUB_WINDOW_KEY, String(t));
    }, o.prototype.getLiffId = function () {
      var t = r().liffId;
      if (!t) throw e(i, "liffId is necessary for liff.init()");
      return t;
    }, o;
  }(),
  a = function () {
    function t() {
      f() ? this.impl = new u() : this.impl = new s(window.sessionStorage);
    }
    return Object.defineProperty(t.prototype, "name", {
      get: function () {
        return "isSubWindow";
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.install = function () {
      return this.impl.invoke.bind(this.impl);
    }, t;
  }(),
  l = new a(),
  c = l.install();
export { a as IsSubWindowModule, c as isSubWindow, l as module };