import { INVALID_CONFIG as i } from "@liff/consts";
import { logger as o } from "@liff/logger";
import { hexToBase64 as r, randomAlphaNumericString as e, createLiffError as f, qs as t } from "@liff/util";
import { getConfig as n, setLoginTmp as l } from "@liff/store";
import { getEndPoint as d } from "@liff/server-api";
import { getVersion as p } from "@liff/get-version";
import { isSubWindow as c } from "@liff/is-sub-window";
import { isInClient as s } from "@liff/is-in-client";
import a from "tiny-sha256";
import { SyncHook as m } from "@liff/hooks";
import { getMessageBus as u } from "@liff/sub-window";
var g = function () {
  function g() {
    this.hooks = {
      before: new m()
    };
  }
  return Object.defineProperty(g.prototype, "name", {
    get: function () {
      return "login";
    },
    enumerable: !1,
    configurable: !0
  }), g.prototype.install = function () {
    return this.login.bind(this);
  }, g.prototype.login = function (m) {
    var g;
    this.hooks.before.call(m);
    var h = e(43),
      _ = r(a(h)).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, ""),
      b = n();
    if (!b || !b.liffId) throw f(i, "You need to define `liffId` for liff.login()");
    var v = {
      app_id: b.liffId,
      state: e(12),
      response_type: "code",
      code_challenge_method: "S256",
      code_challenge: _,
      liff_sdk_version: p()
    };
    m && m.redirectUri && (v.redirect_uri = m.redirectUri), c() && !s() && ((null === (g = u()) || void 0 === g ? void 0 : g.isReady()) ? v.redirect_uri = window.location.href : v.disable_auto_login = "true"), l({
      codeVerifier: h
    });
    var w = d("authorize") + "?" + t.stringify(v);
    o.debug("[Redirect] " + w), window.location.href = w;
  }, g;
}();
export { g as LoginModule };