import { __assign as e, __awaiter as t, __generator as r, __read as n } from "tslib";
import { done as o } from "@liff/ready";
import { isLoggedIn as i } from "@liff/is-logged-in";
import { convertHexToRgb as a, convertArgbToRgba as s, createLiffError as l, base64Url as c, convertArrayBuffer as u, compareVersion as f, qs as d, extractChannelIdFromLiffId as h, objectAssignKeyOnlyWithValue as p, addParamsToUrl as v, randomAlphaNumericString as m, replaceUrlCredentialRemoved as w } from "@liff/util";
import { getContext as b, getLoginTmp as g, getExpireTime as C, getIsSubsequentLiffApp as k, getFeatureToken as F, setIsSubsequentLiffApp as y, getMST as _, getAccessToken as I, getRawContext as B, getIDToken as S, getClientId as x, getMSTChallenge as T, getMSTVerifier as A, getMSIT as E, getConfig as L, setDecodedIDToken as N, setAppData as U, setMST as D, setFeatureToken as O, setAccessToken as P, setContext as W, setMSTChallenge as K, setMSTVerifier as M, setClientId as j, setMSIT as R, setIDToken as H, getDecodedIDToken as J, removeLoginTmp as V, setExpireTime as q, setConfig as z } from "@liff/store";
import { load as G } from "@liff/extensions";
import { INIT_FAILED as Q, INVALID_ID_TOKEN as X, FORBIDDEN as Y, SUB_WINDOW_HEALTH_CHECK_MESSAGE as Z, SUB_WINDOW_STATUS as $, INVALID_CONFIG as ee } from "@liff/consts";
import { isInClient as te } from "@liff/is-in-client";
import { logout as re } from "@liff/logout";
import { isSubWindow as ne } from "@liff/is-sub-window";
import { getMSTByMSIT as oe, getAppData as ie, getMainWindowOrigin as ae, setMainWindowOrigin as se, getMessageBus as le, initMessageBus as ce } from "@liff/sub-window";
import { fetch as ue, getEndPoint as fe, verifyAccessToken as de } from "@liff/server-api";
import { logger as he } from "@liff/logger";
import { getLineVersion as pe } from "@liff/get-line-version";
import { addListener as ve, removeListener as me } from "@liff/native-bridge";
import { isApiAvailable as we } from "@liff/is-api-available";
import { getOS as be } from "@liff/get-os";
import { closeWindow as ge } from "@liff/close-window";
import { EVENT_NAME as Ce, WINDOW as ke, IDENTIFIER_KEY as Fe } from "@liff/message-bus";
import { AsyncHook as ye } from "@liff/hooks";
var _e = {
    iconColor: "#111111",
    statusBarColor: "BLACK",
    titleTextColor: "#111111",
    titleSubtextColor: "#B7B7B7",
    titleButtonColor: "#111111",
    titleBackgroundColor: "#FFFFFF",
    progressBarColor: "#06C755",
    progressBackgroundColor: "#FFFFFF",
    titleButtonAreaBackgroundColor: "#1FFFFFFF",
    titleButtonAreaBorderColor: "#26000000",
    baseBackgroundColor: "#FFFFFF",
    baseTextColor: "#000000",
    lightButtonBorderColor: "rgba(0, 0, 0, 0.15)"
  },
  Ie = {
    iconColor: "#FFFFFF",
    statusBarColor: "WHITE",
    titleTextColor: "#FFFFFF",
    titleSubtextColor: "#949494",
    titleButtonColor: "#FFFFFF",
    titleBackgroundColor: "#111111",
    progressBarColor: "#06C755",
    progressBackgroundColor: "#111111",
    titleButtonAreaBackgroundColor: "#1FFFFFFF",
    titleButtonAreaBorderColor: "#26000000",
    baseBackgroundColor: "#000000",
    baseTextColor: "#FFFFFF",
    lightButtonBorderColor: "rgba(255, 255, 255, 0.5)"
  };
function Be() {
  var e;
  Te("color-scheme", ((null == (e = b()) ? void 0 : e.menuColorSetting) || {
    adaptableColorSchemes: ["light"]
  }).adaptableColorSchemes.join(" "));
  var t = window.matchMedia("(prefers-color-scheme: dark)");
  Se({
    matches: null == t ? void 0 : t.matches,
    media: null == t ? void 0 : t.media
  }), t.addEventListener ? t.addEventListener("change", Se) : t.addListener && t.addListener(Se);
}
function Se(t) {
  var r = b(),
    n = (null == r ? void 0 : r.menuColorSetting) || {
      adaptableColorSchemes: ["light"],
      lightModeColor: _e,
      darkModeColor: Ie
    },
    o = n.adaptableColorSchemes,
    i = n.lightModeColor,
    a = n.darkModeColor,
    s = o.includes("dark");
  t.matches && s ? xe(e(e({}, Ie), a)) : xe(e(e({}, _e), i));
}
function xe(e) {
  var t = e.iconColor,
    r = e.statusBarColor,
    n = e.titleTextColor,
    o = e.titleSubtextColor,
    i = e.titleButtonColor,
    l = e.titleBackgroundColor,
    c = e.progressBarColor,
    u = e.progressBackgroundColor,
    f = e.titleButtonAreaBackgroundColor,
    d = e.titleButtonAreaBorderColor,
    h = e.baseBackgroundColor,
    p = e.baseTextColor,
    v = e.lightButtonBorderColor;
  Te("--liff-base-background-color", h), Te("--liff-base-text-color", p), Te("--liff-base-background-rgb-color", a(h)), Te("--liff-base-text-rgb-color", a(p)), Te("--liff-light-button-border-color", v), Te("--liff-title-text-color", n), Te("--liff-title-background-color", l), Te("--liff-title-button-color", i), Te("--liff-icon-color", t), Te("--liff-status-bar-color", r), Te("--liff-title-subtext-color", o), Te("--liff-progress-bar-color", c), Te("--liff-progress-background-color", u), Te("--liff-title-button-area-background-color", s(f)), Te("--liff-title-button-area-border-color", s(d));
}
function Te(e, t) {
  document.documentElement.style.setProperty(e, t);
}
function Ae(e) {
  return t(this, void 0, void 0, function () {
    return r(this, function (t) {
      switch (t.label) {
        case 0:
          return [4, G()];
        case 1:
          return t.sent().install(e), [2];
      }
    });
  });
}
function Ee() {
  return t(this, void 0, void 0, function () {
    return r(this, function (e) {
      switch (e.label) {
        case 0:
          return [4, ue(fe("certs"))];
        case 1:
          return [2, e.sent()];
      }
    });
  });
}
function Le(e, n, o) {
  return t(this, void 0, void 0, function () {
    var t;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return [4, crypto.subtle.importKey("jwk", e, {
            name: "ECDSA",
            namedCurve: "P-256"
          }, !1, ["verify"])];
        case 1:
          return t = r.sent(), [4, crypto.subtle.verify({
            name: "ECDSA",
            hash: {
              name: "SHA-256"
            }
          }, t, o, n)];
        case 2:
          return [2, r.sent()];
      }
    });
  });
}
function Ne(e, o) {
  return t(this, void 0, void 0, function () {
    var t, i, a, s, f, d, h, p, v, m, w, b, g, C, k, F;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return t = e.split("."), i = n(t, 3), a = i[0], s = i[1], f = i[2], d = JSON.parse(c.decode(a)), h = JSON.parse(c.decodeUnicode(s)), p = u(c.decode(f)), v = u(a + "." + s), [4, Ee()];
        case 1:
          if (m = r.sent(), !(w = m.keys.find(function (e) {
            return e.kid === d.kid;
          }))) return [3, 6];
          if (delete w.alg, "ES256" !== d.alg) throw l(X, 'Invalid "alg" value in ID_TOKEN');
          b = void 0, r.label = 2;
        case 2:
          return r.trys.push([2, 4,, 5]), [4, Le(w, v, p)];
        case 3:
          return b = r.sent(), [3, 5];
        case 4:
          throw g = r.sent(), l(X, "Failed to use Crypto API to verify ID_TOKEN: " + g);
        case 5:
          if (b) {
            if (C = "https://access.line.me" !== h.iss, k = h.aud !== o, F = 1e3 * h.exp < Date.now(), C) throw l(X, 'Invalid "iss" value in ID_TOKEN');
            if (k) throw l(X, 'Invalid "aud" value in ID_TOKEN');
            if (F) throw l(X, 'Invalid "exp" value in ID_TOKEN');
            return [2, h];
          }
          throw l(X, "Invalid signature in ID_TOKEN");
        case 6:
          throw l(X, 'Invalid "kid" value in ID_TOKEN');
        case 7:
          return [2];
      }
    });
  });
}
function Ue(e) {
  var t = e.split(".");
  if (t[1]) try {
    var r = t[1].replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(r));
  } catch (n) {
    return null;
  }
  return null;
}
function De(e) {
  var t = e.pathname,
    r = e.query,
    n = "liff://" + t + (r ? "?" + d.stringify(r) : "");
  location.href = n;
}
var Oe = null;
function Pe() {
  "boolean" == typeof Oe && he.warn("liff.init is not expected to be called more than once"), Oe = !!k() || !(!te() || d.parse(window.location.hash).feature_token || F()) && (y(!0), !0);
}
function We() {
  return Boolean(Oe);
}
function Ke(e, n) {
  return t(this, void 0, void 0, function () {
    var t;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return (t = _()) ? [2, t] : e && n ? [4, oe({
            msit: e,
            mstVerifier: n
          })] : [3, 2];
        case 1:
          return [2, r.sent().mst];
        case 2:
          return [2, null];
      }
    });
  });
}
function Me(e) {
  return ue(fe("apps") + "/" + e + "/featureToken");
}
function je(e) {
  return t(this, void 0, void 0, function () {
    var t, n, o, a;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return t = d.parse(window.location.hash), n = p({
            access_token: I(),
            context_token: B(),
            feature_token: F(),
            id_token: S(),
            client_id: x(),
            mst_challenge: T(),
            mst_verifier: A(),
            msit: E()
          }, t), We() ? i() ? [4, Me(e)] : [3, 2] : [3, 3];
        case 1:
          o = r.sent().featureToken, n.feature_token || (n.feature_token = o), r.label = 2;
        case 2:
          (a = h(e)) && (n.client_id = a), r.label = 3;
        case 3:
          return [2, n];
      }
    });
  });
}
function Re(e) {
  if (e.persisted && we("multipleLiffTransition")) if ("ios" === be()) window.location.reload();else {
    var t = L().liffId,
      r = F();
    if (!t) throw l(Q, "Invalid LIFF ID.");
    if (!r) throw l(Y, "Invalid featureToken for client features");
    De({
      pathname: "app/" + t,
      query: {
        feature_token: r
      }
    });
  }
}
function He(e, n) {
  return t(this, void 0, void 0, function () {
    var t, o;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return [4, de(e)];
        case 1:
          return t = r.sent().client_id, o = h(n), [2, t === o];
      }
    });
  });
}
function Je(e, n) {
  return t(this, void 0, void 0, function () {
    var t, o, a, s, c, u, d, h, p, m, w, b, g;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return [4, new Promise(function (e) {
            var t = pe();
            if (!t || f(t, "9.5.0") < 0) e();else if (window._liff && window._liff.features) e();else {
              he.debug("cannot find window._liff.features, listen to ready event");
              var r = function () {
                he.debug("ready event is fired"), me("ready", r), e();
              };
              ve("ready", r);
            }
          })];
        case 1:
          return r.sent(), Pe(), [4, je(n.liffId)];
        case 2:
          if (t = r.sent(), o = t.access_token, a = t.context_token, s = t.feature_token, c = t.id_token, u = t.client_id, d = t.mst_verifier, h = t.mst_challenge, p = t.msit, a) {
            if ("string" != typeof a) throw l(Q, "Cannot get context token, perhaps there is an incorrect parameter in permanent link");
            W(Ue(a));
          }
          return !ne() && s && (!function (e, t) {
            we("multipleLiffTransition") && De({
              pathname: "app/" + e,
              query: {
                feature_token: t
              }
            });
          }(n.liffId, s), We() && O(s)), h && K(h), d && M(d), u && j(u), p && R(p), window.addEventListener("pageshow", Re), i() ? [3, 7] : s && o ? [3, 5] : We() ? (m = v(location.href, {
            "liff.hback": "2"
          }), e.login({
            redirectUri: m
          }), [4, new Promise(function () {})]) : [3, 4];
        case 3:
          r.sent(), r.label = 4;
        case 4:
          throw e.login(), l(Q, "Failed to parse feature_token or access_token");
        case 5:
          return [4, He(o, n.liffId)];
        case 6:
          if (!r.sent()) throw e.login(), l(Q, "Failed to verify access_token");
          O(s), P(o), r.label = 7;
        case 7:
          return [4, Ke(p, d)];
        case 8:
          return (w = r.sent()) ? (D(w), [4, ie({
            mst: w
          })]) : [3, 10];
        case 9:
          (b = r.sent().data) && U(JSON.stringify(b)), r.label = 10;
        case 10:
          return c && !S() && H(c), c && u && !J() ? [4, Ne(c, u)] : [3, 12];
        case 11:
          (g = r.sent()) && N(g), r.label = 12;
        case 12:
          return [2];
      }
    });
  });
}
function Ve(e) {
  return t(this, void 0, void 0, function () {
    var t, n, o, i, a, s, c;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return t = fe("apps"), n = t + "/" + e + "/contextToken", o = I(), i = {
            "Content-Type": "application/json",
            Accept: "application/json"
          }, o && (i.Authorization = "Bearer " + o), [4, ue(n, {
            headers: i
          })];
        case 1:
          if (a = r.sent(), !(s = a.contextToken)) throw l(Q, "Can not get context from server.");
          if (!(c = Ue(s))) throw l(Q, "Invalid context token.");
          return [2, c];
      }
    });
  });
}
function qe() {
  return t(this, void 0, void 0, function () {
    var e, t;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          if (!(e = L().liffId)) throw l(Q, "Invalid LIFF ID.");
          return [4, Ve(e)];
        case 1:
          return t = r.sent(), W(t), [2];
      }
    });
  });
}
function ze(e) {
  return t(this, void 0, void 0, function () {
    var n,
      o,
      i,
      a = this;
    return r(this, function (s) {
      switch (s.label) {
        case 0:
          n = function () {
            return t(a, void 0, void 0, function () {
              var t, n, o, i, a, s;
              return r(this, function (r) {
                switch (r.label) {
                  case 0:
                    return [4, (l = L(), c = d.parse(window.location.search), u = g(), f = {
                      grant_type: "authorization_code",
                      client_id: c.liffClientId,
                      appId: l.liffId,
                      code: c.code,
                      code_verifier: u.codeVerifier,
                      redirect_uri: l.redirectUri || c.liffRedirectUri,
                      id_token_key_type: "JWK"
                    }, h = d.stringify(f), ue(fe("token"), {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                      },
                      body: h
                    }))];
                  case 1:
                    return t = r.sent(), n = t.access_token, o = t.id_token, i = t.expires_in, j(e), P(n), q(new Date(Date.now() + 1e3 * i)), V(), o ? (H(o), [4, Ne(o, e)]) : [3, 3];
                  case 2:
                    (a = r.sent()) && N(a), r.label = 3;
                  case 3:
                    return (s = d.parse(location.hash).context_token) ? (W(Ue(s)), [3, 6]) : [3, 4];
                  case 4:
                    return [4, qe()];
                  case 5:
                    r.sent(), r.label = 6;
                  case 6:
                    return [2];
                }
                var l, c, u, f, h;
              });
            });
          }, s.label = 1;
        case 1:
          return s.trys.push([1, 3,, 4]), [4, n()];
        case 2:
          return s.sent(), [3, 4];
        case 3:
          throw o = s.sent(), i = o, V(), i;
        case 4:
          return [2];
      }
    });
  });
}
function Ge() {
  return t(this, void 0, void 0, function () {
    var e,
      n,
      o,
      a,
      s,
      l,
      c = this;
    return r(this, function (u) {
      switch (u.label) {
        case 0:
          return (n = le()) ? [3, 2] : [4, ce(ke.SUB)];
        case 1:
          n = u.sent(), u.label = 2;
        case 2:
          return (e = n).isReady() ? (o = m(8), [4, e.getData("appData")]) : [3, 8];
        case 3:
          return a = u.sent(), s = a.eventName, l = a.data, s !== Ce.NOT_FOUND ? [3, 6] : [4, e.teardown()];
        case 4:
          return u.sent(), [4, Ge()];
        case 5:
          return [2, u.sent()];
        case 6:
          l && U(JSON.stringify(l)), u.label = 7;
        case 7:
          return e.listen(function (n) {
            return t(c, void 0, void 0, function () {
              var t, i;
              return r(this, function (r) {
                return t = n.context, i = t.data, t.eventName === $.INIT && (null == i ? void 0 : i.subWindowId) !== o && ge(), t.eventName !== $.CANCEL && t.eventName !== $.SUBMIT || e.teardown(), [2];
              });
            });
          }), i() && e.send({
            eventName: $.INIT,
            data: {
              subWindowId: o,
              hasOpener: !!window.opener
            }
          }), [3, 10];
        case 8:
          return ae() ? [3, 10] : [4, new Promise(function (e) {
            window.addEventListener("message", function (e) {
              return function t(r) {
                var n = r.data,
                  o = r.source,
                  i = r.origin;
                if (n) {
                  var a = n.type,
                    s = n.message;
                  a === Z && (window.removeEventListener("message", t), s && U(s), se(i), o && o.postMessage && o.postMessage({
                    status: Z
                  }, i), e());
                }
              };
            }(e));
          })];
        case 9:
          return [2, u.sent()];
        case 10:
          return [2];
      }
    });
  });
}
var Qe = new (function () {
  function e() {
    var e = this;
    this.getAndValidateContext = function () {
      var e = b();
      if (!e) throw l(Q, "Could not get Context from server.");
      if (!e.endpointUrl) throw l(Q, "Could not get endpointUrl from server.");
      if (!e.permanentLinkPattern) throw l(Q, "Could not get permanentLinkPattern from server.");
      return e;
    }, this.decodeState = function (t) {
      var r = e.getAndValidateContext();
      t = t.replace(/\n/g, "%0D%0A");
      var n = !r.endpointUrl.startsWith("/?") && r.endpointUrl.includes("/?") || !r.endpointUrl.startsWith("/#") && r.endpointUrl.includes("/#") || r.endpointUrl.endsWith("/") || !t.startsWith("/?") && t.includes("/?") || !t.startsWith("/#") && t.includes("/#") || t.endsWith("/"),
        o = new URL(r.endpointUrl),
        i = o.origin,
        a = o.pathname,
        s = o.search,
        l = new URL("" + i + e.attachSlashAtStart(t)),
        c = l.pathname,
        u = l.search,
        f = l.hash,
        d = "" + s + (s ? u.replace(/\?/g, "&") : u),
        h = ("" + a + e.attachSlashAtStart(c)).replace("//", "/");
      return (h = e.attachSlashAtStart("" + h)).endsWith("/") && !n && (h = h.substring(0, h.length - 1)), ("" + i + h + d + f).replace(/%0D%0A/g, "\n");
    };
  }
  return e.prototype.attachSlashAtStart = function (e) {
    return (e && e.length > 0 && !e.startsWith("/") ? "/" : "") + e;
  }, e.prototype.invoke = function () {
    return t(this, void 0, void 0, function () {
      var e, t, n, o, i;
      return r(this, function (r) {
        switch (r.label) {
          case 0:
            if (e = d.parse(window.location.search), "string" != typeof (t = e["liff.state"])) return [2];
            r.label = 1;
          case 1:
            return r.trys.push([1, 4,, 5]), n = location.href, (o = this.decodeState(t)) === n ? [3, 3] : (e["liff.hback"] ? location.replace(v(o, {
              "liff.hback": e["liff.hback"]
            })) : location.replace(o), [4, new Promise(function () {})]);
          case 2:
            r.sent(), r.label = 3;
          case 3:
            return [3, 5];
          case 4:
            if ((i = r.sent()).code === Q) throw i;
            return he.debug(i), [3, 5];
          case 5:
            return [2];
        }
      });
    });
  }, e;
}())();
function Xe(e, n, o) {
  return t(this, void 0, void 0, function () {
    var t;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          if (!n.liffId) throw l(ee, "liffId is necessary for liff.init()");
          return z(n), !te() && i() && (C() || re()), t = d.parse(window.location.search), !ne() || te() ? [3, 2] : [4, Ge()];
        case 1:
          r.sent(), r.label = 2;
        case 2:
          if (t.error && t.liffOAuth2Error) throw c = t.error, u = t.error_description, f = u.replace(/\+/g, " "), l(Q, c + ": " + f);
          return a = t.code, s = g(), Boolean(a && !i() && s && s.codeVerifier) ? [4, ze(t.liffClientId)] : [3, 4];
        case 3:
          r.sent(), r.label = 4;
        case 4:
          return te() ? [4, Je(e, n)] : [3, 6];
        case 5:
          return r.sent(), [3, 8];
        case 6:
          return i() ? [3, 8] : [4, qe()];
        case 7:
          r.sent(), r.label = 8;
        case 8:
          return [4, Qe.invoke()];
        case 9:
          return r.sent(), [4, o()];
        case 10:
          return r.sent(), w(window.location.href), [2];
      }
      var a, s, c, u, f;
    });
  });
}
var Ye = function (e, t) {
    return new Promise(function (r, n) {
      if (e) {
        var o = document.createElement("script");
        o.type = "module", o.onload = function () {
          r();
        }, o.src = e, document.head.appendChild(o);
      } else n(l(ee, t));
    });
  },
  Ze = function (e) {
    var t = "https://static.line-scdn.net/lui/edge/versions/1.13.0/lui-alert.js";
    return t && e && (t = t.replace(/\d{1,2}\.\d{1,2}\.\d{1,3}/, e)), Ye(t, "LUI_ALERT_URL is not defined");
  },
  $e = function () {
    return t(void 0, void 0, void 0, function () {
      var e;
      return r(this, function (t) {
        switch (t.label) {
          case 0:
            return (e = function () {
              var e,
                t = document.querySelector('script[src*="luivendor.js"]');
              if (t && (null === (e = t.src.match(/\d{1,2}\.\d{1,2}\.\d{1,3}/g)) || void 0 === e ? void 0 : e.length)) return t.src.match(/\d{1,2}\.\d{1,2}\.\d{1,3}/g)[0];
            }()) ? [3, 2] : [4, Ye("https://static.line-scdn.net/lui/edge/versions/1.13.0/luivendor.js", "LUI_VENDOR_URL is not defined")];
          case 1:
            t.sent(), t.label = 2;
          case 2:
            return [4, Ze(e)];
          case 3:
            return t.sent(), [4, (r = m(6), new Promise(function () {
              var e = document.createElement("div");
              e.innerHTML = '<lui-alert id="liffAlert-' + r + '" shown title="Login successfully!"></lui-alert>', document.body.appendChild(e);
              var t = document.getElementById("liffAlert-" + r);
              t && t.addEventListener("lui-button-click", function () {
                var e = window.open(window.location.href + "&liffIsEscapedFromApp=true", "_blank");
                e && (e.location.href = window.location.href + "&liffIsEscapedFromApp=true", window.close());
              });
            }))];
          case 4:
            return t.sent(), [2];
        }
        var r;
      });
    });
  };
function et() {
  return t(this, void 0, void 0, function () {
    var e;
    return r(this, function (t) {
      switch (t.label) {
        case 0:
          return te() || "android" !== be() || (e = d.parse(window.location.search))[Fe] || e.liffIsEscapedFromApp ? [3, 6] : e.liffClientId && document.referrer.includes("access.line.me") ? (window.location.href = window.location.href + "&liffIsEscapedFromApp=true", [2]) : e.liffClientId && document.referrer.includes("android-app://") ? [4, $e()] : [3, 2];
        case 1:
          t.sent(), t.label = 2;
        case 2:
          return e.liffClientId && "" === document.referrer && 1 === window.history.length ? [4, $e()] : [3, 4];
        case 3:
          t.sent(), t.label = 4;
        case 4:
          return !document.referrer.includes("liffClientId") || document.referrer.includes("liffIsEscapedFromApp") ? [3, 6] : [4, $e()];
        case 5:
          t.sent(), t.label = 6;
        case 6:
          return [2];
      }
    });
  });
}
var tt = function () {
  function e() {
    this.hooks = {
      before: new ye(),
      after: new ye()
    }, this.internalHooks = {
      beforeFinished: new ye(),
      beforeSuccess: new ye(),
      error: new ye()
    };
  }
  return Object.defineProperty(e.prototype, "name", {
    get: function () {
      return "init";
    },
    enumerable: !1,
    configurable: !0
  }), e.prototype.install = function (e) {
    var t = e.liff;
    return this.liff = t, this.init.bind(this);
  }, e.prototype.init = function (e, n, a) {
    return t(this, void 0, void 0, function () {
      var t;
      return r(this, function (r) {
        switch (r.label) {
          case 0:
            return [4, this.hooks.before.call()];
          case 1:
            r.sent(), s = this.liff, window && !window.liff && (window.liff = s), r.label = 2;
          case 2:
            return r.trys.push([2, 9,, 11]), [4, Promise.all([Ae(this.liff), Xe(this.liff, e, this.internalHooks.beforeFinished.call)])];
          case 3:
            return r.sent(), Be(), [4, this.internalHooks.beforeSuccess.call()];
          case 4:
            return r.sent(), !e.withLoginOnExternalBrowser || i() ? [3, 6] : (this.liff.login(), [4, new Promise(function () {})]);
          case 5:
            r.sent(), r.label = 6;
          case 6:
            return [4, et()];
          case 7:
            return r.sent(), [4, this.hooks.after.call()];
          case 8:
            return r.sent(), "function" == typeof n && n(), o(), [3, 11];
          case 9:
            return t = r.sent(), [4, this.internalHooks.error.call(t)];
          case 10:
            throw r.sent(), "function" == typeof a && a(t), t;
          case 11:
            return [2];
        }
        var s;
      });
    });
  }, e;
}();
export { tt as InitModule };