import { __assign as e, __awaiter as t, __generator as a } from "tslib";
import { UNKNOWN as n, UNAUTHORIZED as r } from "@liff/consts";
import { createLiffError as i, HTTPStatusCodes as s } from "@liff/util";
import { getAccessToken as o } from "@liff/store";
function u(e) {
  return t(this, void 0, void 0, function () {
    var t, r, o;
    return a(this, function (a) {
      switch (a.label) {
        case 0:
          if (!e.ok) return [3, 4];
          a.label = 1;
        case 1:
          return a.trys.push([1, 3,, 4]), [4, e.json()];
        case 2:
          return [2, a.sent()];
        case 3:
          return a.sent(), [2, e];
        case 4:
          return t = String(e.status), r = s.has(t) ? t : n, [4, e.json().catch(function () {
            throw i(r, e.statusText);
          })];
        case 5:
          throw o = a.sent(), i(o.error || r, o.error_description || o.message);
      }
    });
  });
}
function f(t) {
  var a = function (e) {
    if (e) return e;
    var t = o();
    if (!t) throw i(r, "Need access_token for api call, Please login first");
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + t
    };
  }(t && t.headers);
  return e(e({}, t), {
    headers: a
  });
}
function c(e, t) {
  var a;
  try {
    a = f(t);
  } catch (n) {
    return Promise.reject(n);
  }
  return fetch(e, a).then(u);
}
function p(e, t) {
  var a;
  try {
    a = f(t);
  } catch (n) {
    return Promise.reject(n);
  }
  return fetch(e, a);
}
function h(e) {
  var t = e.subdomain;
  return "https://" + (void 0 === t ? "api" : t) + ".line.me/" + e.pathname;
}
var m = {
  token: h({
    pathname: "oauth2/v2.1/token"
  }),
  certs: h({
    pathname: "oauth2/v2.1/certs"
  }),
  "openid-configuration": h({
    subdomain: "access",
    pathname: ".well-known/openid-configuration"
  }),
  authorize: h({
    subdomain: "access",
    pathname: "liff/v1/authorize"
  }),
  profile: h({
    pathname: "v2/profile"
  }),
  message: h({
    pathname: "message/v3/share"
  }),
  messageOTT: h({
    pathname: "message/v3/multisend?type=ott"
  }),
  friendship: h({
    pathname: "friendship/v1/status"
  }),
  shareTargetPicker: h({
    subdomain: "access",
    pathname: "oauth2/v2.1/liff/shareTargetPicker"
  }),
  shareTargetPickerOtt: h({
    pathname: "liff/v2/apps"
  }),
  shareTargetPickerResult: h({
    subdomain: "access",
    pathname: "oauth2/v2.1/liff/shareTargetPicker/result"
  }),
  apps: h({
    pathname: "liff/v2/apps"
  }),
  subWindowGetMSIT: h({
    pathname: "liff/v2/sub/msit"
  }),
  subWindowGetMSTByMSIT: h({
    pathname: "liff/v2/sub/mst"
  }),
  subWindowSubscribe: h({
    subdomain: "liff",
    pathname: "liff/v2/sub/waitResult"
  }),
  subWindowPost: h({
    pathname: "liff/v2/sub/result"
  }),
  subWindowGetAppData: h({
    pathname: "liff/v2/sub/appData"
  }),
  subWindowGetOrigin: function (e) {
    return h({
      pathname: "liff/v2/sub/" + e + "/origin"
    });
  },
  accessTokenVerify: h({
    pathname: "oauth2/v2.1/verify"
  }),
  unauthorizedPermissions: h({
    subdomain: "liff",
    pathname: "liff/v2/incrementalAgreement/unauthorizedPermissions"
  }),
  permanentLink: h({
    subdomain: "liff",
    pathname: "liff/v2/permanentLink"
  })
};
function l(e) {
  return m[e];
}
function v(e) {
  return c(l("accessTokenVerify") + "?access_token=" + encodeURIComponent(e), {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });
}
export { c as fetch, l as getEndPoint, p as requestWithoutErrorHandling, v as verifyAccessToken };