import { __awaiter as t, __generator as r } from "tslib";
import { UNKNOWN as e, INIT_FAILED as n, INVALID_CONFIG as i, CREDENTIAL_KEYS as o, PERMANENT_LINK_ORIGIN as a, INVALID_ARGUMENT as s } from "@liff/consts";
import { createLiffError as f } from "@liff/util";
import { getContext as u, getConfig as l } from "@liff/store";
import { getEndPoint as c } from "@liff/server-api";
function h(n) {
  return t(this, void 0, void 0, function () {
    var t, i;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return [4, fetch(c("permanentLink"), {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            },
            method: "POST",
            body: JSON.stringify(n)
          })];
        case 1:
          return (t = r.sent()).ok ? [3, 3] : [4, t.json()];
        case 2:
          throw i = r.sent().message, f(e, i);
        case 3:
          return [4, t.json()];
        case 4:
          return [2, r.sent()];
      }
    });
  });
}
var p = function () {
    function e() {
      var e = this;
      this.extraParams = "", this.getAndValidateContext = function () {
        var t = u();
        if (!t) throw f(n, "Could not get Context from server.");
        if (!t.endpointUrl) throw f(n, "Could not get endpointUrl from server.");
        if (!t.permanentLinkPattern) throw f(n, "Could not get permanentLinkPattern from server.");
        return t;
      }, this.createUrl = function () {
        var t = e.getAndValidateContext(),
          r = window.location,
          n = r.pathname,
          s = r.search,
          u = r.hash,
          c = r.origin,
          h = new URL(t.endpointUrl);
        if (h.origin !== c || !e.isAncestor(h.pathname, n)) throw f(i, "Current page is not under entrypoint.");
        var p = n.substring(h.pathname.length);
        p.length > 0 && "/" !== p[0] && (p = "/" + p);
        var m = new RegExp("^" + o.join("|")),
          d = u.substring(1).split("&").filter(function (t) {
            return !m.test(t) && Boolean(t);
          }).join("&"),
          g = d === h.hash.substring(1) ? "" : d,
          v = function (t) {
            return t.substring(1).split("&").filter(function (t) {
              return !/liff\.state/.test(t) && Boolean(t);
            });
          },
          w = v(s),
          x = v(h.search);
        e.extraParams && w.push(e.extraParams);
        for (var U = 0; U < x.length; U++) {
          var y = x[U],
            b = w.indexOf(y);
          b > -1 && w.splice(b, 1);
        }
        var P = w.join("&"),
          j = p + ("" !== P ? "?" + P : "") + (g ? "#" + g : "");
        return "" + a + l().liffId + j;
      }, this.createUrlBy = function (i) {
        return t(e, void 0, void 0, function () {
          var t, e;
          return r(this, function (r) {
            switch (r.label) {
              case 0:
                if (!(t = l().liffId)) throw f(n, "Should run after liff init.");
                try {
                  e = new URL(i);
                } catch (o) {
                  throw f(s, "invalid URL.");
                }
                return [4, h({
                  liffId: t,
                  currentPageUrl: e.toString()
                })];
              case 1:
                return [2, r.sent().permanentLinkUrl];
            }
          });
        });
      }, this.setExtraQueryParam = function (t) {
        e.extraParams = t;
      }, this.isAncestor = function (t, r) {
        return 0 === r.indexOf(t) && (t.endsWith("/") && (t = t.substring(0, t.length - 1)), void 0 === r[t.length] || "/" === r[t.length]);
      }, this.install = function () {
        return {
          createUrl: e.createUrl,
          createUrlBy: e.createUrlBy,
          setExtraQueryParam: e.setExtraQueryParam
        };
      };
    }
    return Object.defineProperty(e.prototype, "name", {
      get: function () {
        return "permanentLink";
      },
      enumerable: !1,
      configurable: !0
    }), e;
  }(),
  m = new p();
export { p as PermanentLink, m as module };