import { Inject, Injectable } from '@angular/core'
import { IConfiguration } from '../../../common'
import { APP_CONFIG } from '../../app-instance'

@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
  constructor(@Inject(APP_CONFIG) private appConfig: IConfiguration) {}

  getConfig(): IConfiguration {
    return this.appConfig
  }
}
