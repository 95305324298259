import { logger as n } from "@liff/logger";
import { __extends as r, __read as e, __makeTemplateObject as t, __values as o } from "tslib";
import { CREDENTIAL_KEYS as i, PERMANENT_LINK_ORIGIN as a } from "@liff/consts";
function c(n) {
  return window.atob(n.replace(/-/g, "+").replace(/_/g, "/"));
}
var u = {
  decode: c,
  encode: function (n) {
    return window.btoa(n).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
  },
  decodeUnicode: function (n) {
    var r = c(n).split("").map(function (n) {
      return "%" + ("00" + n.charCodeAt(0).toString(16)).slice(-2);
    }).join("");
    return decodeURIComponent(r);
  }
};
function f(n, r) {
  if (n === r) return 0;
  for (var e = n.split("."), t = r.split("."), o = Math.max(e.length, t.length), i = 0; i < o; i++) {
    e[i] || (e[i] = "0"), t[i] || (t[i] = "0");
    var a = parseInt(e[i]) - parseInt(t[i]);
    if (0 !== a) return a > 0 ? 1 : -1;
  }
  return 0;
}
function l(r) {
  var e = r.replace("#", "").match(/.{2}/g) || [];
  if (4 !== e.length) return n.warn("convertArgbToRgba: Received invalid ARGB color"), "";
  var t = function (n) {
    var r = p(n);
    return Math.round(r / 255 * 100) / 100;
  }(e[0]);
  return "rgba(" + p(e[1]) + ", " + p(e[2]) + ", " + p(e[3]) + ", " + t + ")";
}
function p(n) {
  return parseInt(n, 16);
}
function d(r) {
  var e = r.replace("#", "").match(/.{2}/g) || [];
  return 3 !== e.length ? (n.warn("convertArgbToRgba: Received invalid hex color"), "") : p(e[0]) + ", " + p(e[1]) + ", " + p(e[2]);
}
function s(n) {
  for (var r = n.length, e = new ArrayBuffer(r), t = new Uint8Array(e), o = 0; o < r; o++) t[o] = n.charCodeAt(o);
  return e;
}
var v = {
    get: function (n) {
      var r = new RegExp("(?:(?:^|.*;\\s*)" + n + "\\s*\\=\\s*([^;]*).*$)|^.*$");
      return document.cookie.replace(r, "$1");
    },
    set: function (r, e, t) {
      var o = r + "=" + e;
      if (t) for (var i in t) {
        o += "; " + i + (t[i] ? "=" + t[i] : "");
      }
      n.debug("set cookie", o), document.cookie = o;
    },
    remove: function (n, r) {
      var e = n + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      if (r) for (var t in r) e += "; " + t + "=" + r[t];
      document.cookie = e;
    }
  },
  g = new Set(["400", "401", "403", "404", "429", "500"]),
  h = function (n) {
    function e(r, e) {
      var t = n.call(this, e) || this;
      return t.code = r, t;
    }
    return r(e, n), e;
  }(Error);
function m(n, r) {
  return new h(n, r || "");
}
function w(n) {
  var r = n.match(/([^-]+)-[^-]+/);
  return r && r[1];
}
function y(n) {
  var r = "";
  return n.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ").forEach(function (n) {
    r += String.fromCharCode(parseInt(n));
  }), window.btoa(r);
}
var b = new (function () {
  function n() {
    this.map = {};
  }
  return n.prototype.clear = function () {
    this.map = {};
  }, n.prototype.getItem = function (n) {
    var r = this.map[n];
    return void 0 === r ? null : r;
  }, n.prototype.setItem = function (n, r) {
    this.map[n] = r;
  }, n.prototype.removeItem = function (n) {
    delete this.map[n];
  }, n.prototype.key = function (n) {
    var r = Object.keys(this.map)[n];
    return void 0 === r ? null : r;
  }, Object.defineProperty(n.prototype, "length", {
    get: function () {
      return Object.keys(this.map).length;
    },
    enumerable: !1,
    configurable: !0
  }), n;
}())();
var A = {
    parse: function (n) {
      return n.replace(/^\?/, "").replace(/^#\/?/, "").split(/&+/).filter(function (n) {
        return n.length > 0;
      }).reduce(function (n, r) {
        var t = e(r.split("=").map(decodeURIComponent), 2),
          o = t[0],
          i = t[1],
          a = n[o];
        return Array.isArray(a) ? a.push(i) : Object.prototype.hasOwnProperty.call(n, o) ? n[o] = [a, i] : n[o] = i, n;
      }, {});
    },
    stringify: function (n) {
      return Object.keys(n).map(function (r) {
        var e = n[r],
          t = function (n) {
            return void 0 !== n ? encodeURIComponent(r) + "=" + encodeURIComponent(n) : encodeURIComponent(r);
          };
        return Array.isArray(e) ? e.map(function (n) {
          return t(n);
        }).join("&") : t(e);
      }).join("&");
    }
  },
  R = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
function I() {
  return window.crypto.getRandomValues(new Uint32Array(1))[0] / 4294967296;
}
function U(n) {
  for (var r = "", e = 0; e < n; e++) r += R[Math.floor(I() * R.length)];
  return r;
}
function x(n) {
  var r = new URL(n),
    e = r.hash.slice(1).split("&").filter(function (n) {
      return !i.some(function (r) {
        return n.includes(r + "=");
      });
    }).join("&");
  return r.hash = e, r.toString();
}
function j(n) {
  var r = new URL(n);
  return r.toString().replace(new RegExp(String.raw(C || (C = t(["^", ""], ["^", ""])), r.origin)), "");
}
var C,
  L = function (n) {
    var r = j(x(n));
    window.history.replaceState(history.state, "", r);
  };
function O(n, r) {
  if (!n) throw new Error("addParamsToUrl: invalid URL");
  var t = new URL(n);
  return Object.entries(r).forEach(function (n) {
    var r = e(n, 2),
      o = r[0],
      i = r[1];
    t.searchParams.set(o, i);
  }), t.toString();
}
function k(n) {
  var r,
    e = n.match((r = a.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), new RegExp("^" + r + "(\\d+-\\w+)")));
  return e && e[1];
}
function S(n) {
  var r = n.match(/^(https?:\/\/.*?)\//);
  return r && r[1] || "";
}
function E(n) {
  void 0 === n && (n = window.navigator.userAgent);
  var r = n.toLowerCase();
  return -1 !== r.indexOf("msie") || -1 !== r.indexOf("trident");
}
function $(n) {
  return void 0 === n && (n = window.navigator.userAgent), /ipad/.test(n.toLowerCase());
}
function F(n) {
  return void 0 === n && (n = window.navigator.userAgent), /Line\/\d+\.\d+\.\d+/.test(n);
}
function T(n) {
  return void 0 === n && (n = window.navigator.userAgent), /Line\/\d+\.\d+\.\d+ LIFF/.test(n);
}
function M(n) {
  return void 0 === n && (n = window.navigator.userAgent), /LIFF\/SubWindow/.test(n);
}
function P(n) {
  for (var r, e, t = [], i = 1; i < arguments.length; i++) t[i - 1] = arguments[i];
  var a = function (r) {
    Object.keys(r).filter(function (n) {
      return null !== r[n] && void 0 !== r[n];
    }).forEach(function (e) {
      n[e] = r[e];
    });
  };
  try {
    for (var c = o(t), u = c.next(); !u.done; u = c.next()) {
      var f = u.value;
      a(f);
    }
  } catch (l) {
    r = {
      error: l
    };
  } finally {
    try {
      u && !u.done && (e = c.return) && e.call(c);
    } finally {
      if (r) throw r.error;
    }
  }
  return n;
}
export { g as HTTPStatusCodes, h as LiffError, O as addParamsToUrl, u as base64Url, f as compareVersion, l as convertArgbToRgba, s as convertArrayBuffer, d as convertHexToRgb, v as cookie, m as createLiffError, w as extractChannelIdFromLiffId, k as extractLiffId, S as getOriginOfUrl, I as getRandomValue, y as hexToBase64, b as inMemoryStorage, E as isIE, $ as isIpad, T as isLIFFBrowser, F as isLINEBrowser, M as isSubWindow, P as objectAssignKeyOnlyWithValue, A as qs, U as randomAlphaNumericString, x as removeCredential, j as removeOrigin, L as replaceUrlCredentialRemoved };