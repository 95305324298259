import { STORE_KEY as e, INVALID_CONFIG as t, EXCEPTION_IN_SUBWINDOW as n, SUB_WINDOW_STATUS as r, CREATE_SUBWINDOW_FAILED as i, SUB_WINDOW_HEALTH_CHECK_INTERVAL as s, SUB_WINDOW_MONITOR_CLOSE_INTERVAL as o, SUB_WINDOW_IDNTIFICATION_KEY as u, INVALID_ARGUMENT as a, SUB_WINDOW_HEALTH_CHECK_MESSAGE as c, UNKNOWN as f, UNAUTHORIZED as l, SUB_WINDOW_MODAL_SCHEME_URL as d, FORBIDDEN as m } from "@liff/consts";
import { createLiffError as v, inMemoryStorage as p, isIpad as h, extractLiffId as w, isLIFFBrowser as g, isLINEBrowser as b } from "@liff/util";
import { isInClient as S } from "@liff/is-in-client";
import { isApiAvailable as y } from "@liff/is-api-available";
import { __awaiter as I, __generator as C, __spread as O, __read as P } from "tslib";
import { logger as L } from "@liff/logger";
import { getOS as E } from "@liff/get-os";
import { fetch as N, getEndPoint as T, requestWithoutErrorHandling as j } from "@liff/server-api";
import { getConfig as M, getMSTChallenge as B, getMST as D, getAppData as J } from "@liff/store";
import R, { WINDOW as U, IDENTIFIER_KEY as W, CRYPTO_KEY as A } from "@liff/message-bus";
import { isSubWindow as x } from "@liff/is-sub-window";
import { closeWindow as k } from "@liff/close-window";
function K(e) {
  var t = T("subWindowGetOrigin");
  return N(t(e));
}
var G = {};
function V(e, t) {
  e && G[e] && G[e].forEach(function (e) {
    e(t);
  });
}
var F,
  _,
  q,
  z,
  H,
  Q = function () {
    function n(e) {
      this.storage = e;
    }
    return n.prototype.getItem = function (e) {
      return this.storage.getItem(this.getKeyPrefix() + ":" + e);
    }, n.prototype.setItem = function (e, t) {
      this.storage.setItem(this.getKeyPrefix() + ":" + e, t);
    }, n.prototype.removeItem = function (e) {
      this.storage.removeItem(this.getKeyPrefix() + ":" + e);
    }, n.prototype.clear = function () {
      this.storage.clear();
    }, n.prototype.getKeyPrefix = function () {
      return e + ":" + this.getLiffId();
    }, n.prototype.getLiffId = function () {
      var e = M().liffId;
      if (!e) throw v(t, "liffId is necessary for liff.init()");
      return e;
    }, n;
  }(),
  X = new Q(p);
function Y() {
  var e = X.getItem("subWindowStatusUpdated");
  return null !== e && JSON.parse(e);
}
function Z(e) {
  X.setItem("subWindowStatusUpdated", String(e));
}
function $(e) {
  F = e;
}
function ee() {
  return F;
}
function te() {
  return q;
}
function ne() {
  return z;
}
function re(e) {
  return void 0 === e && (e = U.MAIN), I(this, void 0, void 0, function () {
    return C(this, function (t) {
      switch (t.label) {
        case 0:
          return [4, (H = new R(e)).setup()];
        case 1:
          return t.sent(), [2, H];
      }
    });
  });
}
function ie() {
  return H;
}
var se = new Q(window.sessionStorage);
function oe(e) {
  se.setItem("mainWindowOrigin", e);
}
function ue() {
  return se.getItem("mainWindowOrigin");
}
function ae(e, t) {
  return void 0 === t && (t = {}), I(this, void 0, void 0, function () {
    var i, s, o, u, a, c, f, l;
    return C(this, function (d) {
      switch (d.label) {
        case 0:
          if (null == (i = ie()) ? void 0 : i.isReady()) return [3, 5];
          if (s = JSON.stringify(t), o = M().liffId, u = ue(), !window.opener || !u || !o) throw v(n);
          a = !1, d.label = 1;
        case 1:
          return d.trys.push([1, 3,, 4]), [4, K(o)];
        case 2:
          return c = d.sent(), a = c.subwindowCommonModule, [3, 4];
        case 3:
          throw f = d.sent(), L.debug(f), v(n);
        case 4:
          return l = a ? u : location.origin, [2, new Promise(function (t) {
            window.addEventListener("message", function n(i) {
              (function (e) {
                if (e.data && "string" == typeof e.data.type && [r.SUBMIT, r.CANCEL].includes(e.data.type)) return !0;
                return !1;
              })(i) && (window.removeEventListener("message", n), t({
                status: e,
                result: s
              }));
            }), window.opener.postMessage({
              status: e,
              result: s
            }, l);
          })];
        case 5:
          return i.send({
            eventName: e,
            data: t
          }), [4, new Promise(function (e) {
            setTimeout(e, 500);
          })];
        case 6:
          return d.sent(), [2, {
            status: e,
            result: JSON.stringify(t)
          }];
      }
    });
  });
}
function ce(e) {
  var t,
    n = ne();
  if (e.origin === n) {
    var i = e.data;
    if (i) {
      var s,
        o = i.status,
        u = i.result;
      try {
        s = JSON.parse(u || "{}");
      } catch (a) {
        s = {};
      }
      switch (o) {
        case c:
          window.clearInterval(te()), me();
          break;
        case r.CANCEL:
        case r.SUBMIT:
          Z(!0), window.clearInterval(te()), window.removeEventListener("message", ce), V(o, s), null === (t = ee()) || void 0 === t || t.postMessage({
            type: o
          }, ne());
          break;
        default:
          L.debug("unexpected message");
      }
    }
  }
}
var fe = function (e) {
  return I(void 0, void 0, void 0, function () {
    var t, n, i, s;
    return C(this, function (o) {
      if (Y()) return [2];
      switch (t = e.context, n = t.eventName, i = t.data, s = ie(), n) {
        case r.INIT:
          de(!i.hasOpener);
          break;
        case r.CANCEL:
        case r.SUBMIT:
          Z(!0), V(n, i), null == s || s.reply(e, {
            eventName: n
          });
          break;
        case r.CLOSE:
          !1 === Y() && (Z(!0), V(r.CLOSE, {})), me();
      }
      return [2];
    });
  });
};
function le() {
  window.clearInterval(_), window.clearInterval(te()), window.removeEventListener("message", ce);
}
function de(e) {
  if (void 0 === e && (e = !1), le(), Z(!1), e) {
    var t = ee();
    t && (t.close(), $(null));
  }
}
function me() {
  return I(this, void 0, void 0, function () {
    var e;
    return C(this, function (t) {
      switch (t.label) {
        case 0:
          return (e = ie()) ? [4, e.teardown()] : [3, 2];
        case 1:
          t.sent(), t.label = 2;
        case 2:
          return [2];
      }
    });
  });
}
function ve(e) {
  return I(this, void 0, void 0, function () {
    var t, n, f, l, d, m, p, g, b, S;
    return C(this, function (y) {
      switch (y.label) {
        case 0:
          return (t = w(e.url)) ? (de(!0), [4, me()]) : [2, Promise.reject(v(a, "params.url must be liff url"))];
        case 1:
          return y.sent(), n = e.url, f = e.appData, (l = new URL(n)).searchParams.append(u, "true"), [4, re()];
        case 2:
          return d = y.sent(), l.searchParams.append(W, d.identification.identifier), l.searchParams.append(A, d.identification.cryptoKey), l.hostname = function (e) {
            var t = P(e.split(".")),
              n = t[0],
              r = t.slice(1);
            return O([n + "-ext"], r).join(".");
          }(l.hostname), m = l.toString(), $("ios" !== E() || h() ? window.open("", "liffsubwindow", "width=480, height=640, menubar=no, toolbar=no, scrollbars=yes") : window.open()), [4, K(t)];
        case 3:
          if (p = y.sent(), g = p.origin, b = p.subwindowCommonModule, !(S = ee())) throw v(i);
          return b ? (function (e) {
            z = e;
          }(g), d.listen(fe), d.setData("appData", f), window.addEventListener("message", ce), S.location.href = m, I = function (e, t) {
            var n = ee(),
              r = {
                type: c
              };
            return t && (r.message = JSON.stringify(t)), window.setInterval(function () {
              null == n || n.postMessage(r, e);
            }, s);
          }(g, f), q = I, function (e) {
            _ = e;
          }(window.setInterval(function () {
            var e = ee();
            e && e.closed && (le(), $(null), !1 === Y() && (Z(!0), V(r.CLOSE, {})));
          }, o)), [2]) : (S.close(), [2]);
      }
      var I;
    });
  });
}
function pe(e) {
  return I(this, void 0, void 0, function () {
    var t, n, i, s, o, u, c, l, d, m, p;
    return C(this, function (h) {
      switch (h.label) {
        case 0:
          t = e.msit, n = e.mstChallenge, i = e.onSuccess, s = e.onError, o = e.reconnectCount, u = void 0 === o ? 0 : o, h.label = 1;
        case 1:
          return h.trys.push([1, 3,, 6]), [4, j(T("subWindowSubscribe"), {
            method: "POST",
            body: JSON.stringify({
              msit: t,
              mstChallenge: n
            })
          })];
        case 2:
          return c = h.sent(), [3, 6];
        case 3:
          return h.sent(), [4, he()];
        case 4:
          return h.sent(), [4, ge(pe, {
            msit: t,
            mstChallenge: n,
            onSuccess: i,
            onError: s,
            reconnectCount: u += 1
          })];
        case 5:
          return h.sent(), [2];
        case 6:
          return c.status >= 500 ? [4, he()] : [3, 9];
        case 7:
          return h.sent(), [4, ge(pe, {
            msit: t,
            mstChallenge: n,
            onSuccess: i,
            onError: s,
            reconnectCount: u += 1
          })];
        case 8:
          return h.sent(), [3, 20];
        case 9:
          return c.status >= 400 && 500 > c.status ? [4, we(c)] : [3, 11];
        case 10:
          return (d = h.sent()) ? (l = d.errorDetail, s(v(a, l))) : s(v(f, "Some error happened in the server")), [3, 20];
        case 11:
          return 200 !== c.status ? [3, 19] : [4, we(c)];
        case 12:
          return (d = h.sent()) ? [3, 13] : (s(v(f, "Some error happened in the server")), [3, 18]);
        case 13:
          switch (m = d.status, p = d.result, m) {
            case r.ERROR:
              return [3, 14];
            case r.CLOSE:
            case r.CANCEL:
            case r.SUBMIT:
              return [3, 16];
          }
          return [3, 17];
        case 14:
          return [4, ge(pe, {
            msit: t,
            mstChallenge: n,
            onSuccess: i,
            onError: s,
            reconnectCount: u
          })];
        case 15:
          return h.sent(), [3, 18];
        case 16:
          return i(m, p), [3, 18];
        case 17:
          s(v(f, "Some error happened in the server")), h.label = 18;
        case 18:
          return [3, 20];
        case 19:
          s(v(f, "Some error happened in the server")), h.label = 20;
        case 20:
          return [2];
      }
    });
  });
}
function he() {
  return new Promise(function (e) {
    return setTimeout(e, 1e3);
  });
}
function we(e) {
  return I(this, void 0, void 0, function () {
    return C(this, function (t) {
      switch (t.label) {
        case 0:
          return t.trys.push([0, 2,, 3]), [4, e.json()];
        case 1:
          return [2, t.sent()];
        case 2:
          return t.sent(), [2, null];
        case 3:
          return [2];
      }
    });
  });
}
function ge(e, t) {
  return I(this, void 0, void 0, function () {
    return C(this, function (n) {
      switch (n.label) {
        case 0:
          return t.reconnectCount >= 10 ? (t.onError(v(f, "Failed to connect")), [3, 3]) : [3, 1];
        case 1:
          return [4, e(t)];
        case 2:
          n.sent(), n.label = 3;
        case 3:
          return [2];
      }
    });
  });
}
function be(e) {
  var t = {};
  return Object.keys(e).forEach(function (n) {
    "closeButtonColor" === n ? "white" === e[n] ? t[n] = "#ffffff" : t[n] = "#000000" : t[n] = e[n];
  }), t;
}
var Se = {
  height: "full",
  closeButtonPosition: "right",
  closeButtonColor: "black",
  closeButtonLabel: ""
};
function ye(e) {
  var t = e.appData,
    n = e.native,
    i = M().liffId,
    s = B(),
    o = w(e.url);
  if (!i) return Promise.reject(v(l, "liffId is invalid"));
  if (!s) return Promise.reject(v(l, "mst_challenge is invalid"));
  if (!o) return Promise.reject(v(a, "params.url must be liff url"));
  var u = Object.assign({}, Se, n);
  return function (e) {
    var t = e.mainLiffId,
      n = e.subLiffId,
      r = e.mstChallenge,
      i = e.appData,
      s = e.view;
    return t && r ? N(T("subWindowGetMSIT"), {
      method: "POST",
      body: JSON.stringify({
        mainLiffId: t,
        subLiffId: n,
        mstChallenge: r,
        appData: i,
        view: s
      })
    }) : Promise.reject(v(a, "no proper argument"));
  }({
    mainLiffId: i,
    subLiffId: o,
    mstChallenge: s,
    appData: t,
    view: be(u)
  }).then(function (t) {
    var n = t.msit;
    pe({
      msit: n,
      mstChallenge: s,
      onSuccess: function (e, t) {
        V(e, t);
      },
      onError: function (e) {
        V(r.ERROR, e);
      }
    }), function (e, t) {
      var n = e.url,
        r = new URLSearchParams();
      r.set("msit", t), location.href = d + "?url=" + encodeURIComponent(n) + "&" + r.toString();
    }(e, n);
  });
}
function Ie() {
  if (!x()) throw v(l, "this api can be only called in child window");
}
function Ce(e) {
  if (!e.mst || !e.status) return Promise.reject(v(a, "no proper argument"));
  var t = JSON.stringify(e);
  return N(T("subWindowPost"), {
    method: "POST",
    body: t
  });
}
function Oe(e) {
  var t = e.msit,
    n = e.mstVerifier;
  return t && n ? N(T("subWindowGetMSTByMSIT"), {
    method: "POST",
    body: JSON.stringify({
      msit: t,
      mstVerifier: n
    })
  }) : Promise.reject(v(a, "no proper argument"));
}
function Pe(e) {
  var t = e.mst;
  return t ? N(T("subWindowGetAppData"), {
    method: "POST",
    body: JSON.stringify({
      mst: t
    })
  }) : Promise.reject(v(a, "no proper argument"));
}
var Le = {
  on: function (e, t) {
    G[e] || (G[e] = []), G[e].push(t);
  },
  off: function (e, t) {
    if (G[e]) {
      var n = G[e].indexOf(t);
      n >= 0 && G[e].splice(n, 1);
    }
  },
  open: function (e) {
    if (!y("subwindowOpen")) throw v(m, "No permission for liff.subWindow.open()");
    if (!g() && b()) throw v(m, "Subwindow is not supported in this browser");
    return function () {
      if (x()) throw v(l, "this api can be only called in parent window");
    }(), S() ? ye(e) : ve(e);
  },
  cancel: function (e) {
    return void 0 === e && (e = {}), Ie(), S() ? function (e) {
      return void 0 === e && (e = {}), I(this, void 0, void 0, function () {
        var t, n;
        return C(this, function (i) {
          switch (i.label) {
            case 0:
              return (t = D()) ? [4, Ce({
                mst: t,
                status: r.CANCEL,
                result: e
              })] : [2, Promise.reject(v(l, "mst is invalid"))];
            case 1:
              return n = i.sent(), Z(!0), [2, n];
          }
        });
      });
    }(e) : function (e) {
      return void 0 === e && (e = {}), ae(r.CANCEL, e);
    }(e);
  },
  submit: function (e) {
    return void 0 === e && (e = {}), Ie(), S() ? function (e) {
      return void 0 === e && (e = {}), I(this, void 0, void 0, function () {
        var t, n;
        return C(this, function (i) {
          switch (i.label) {
            case 0:
              return (t = D()) ? [4, Ce({
                mst: t,
                status: r.SUBMIT,
                result: e
              })] : [2, Promise.reject(v(l, "mst is invalid"))];
            case 1:
              return n = i.sent(), Z(!0), [2, n];
          }
        });
      });
    }(e) : function (e) {
      return void 0 === e && (e = {}), ae(r.SUBMIT, e);
    }(e);
  },
  close: function () {
    return Ie(), S() ? function () {
      return I(this, void 0, void 0, function () {
        var e;
        return C(this, function (t) {
          switch (t.label) {
            case 0:
              return !1 !== Y() ? [3, 2] : (e = D()) ? [4, Ce({
                mst: e,
                status: r.CLOSE,
                result: {}
              })] : [2, Promise.reject(v(l, "mst is invalid"))];
            case 1:
              t.sent(), t.label = 2;
            case 2:
              return k(), [2];
          }
        });
      });
    }() : function () {
      return I(this, void 0, void 0, function () {
        var e;
        return C(this, function (t) {
          return (null == (e = ie()) ? void 0 : e.isReady()) ? (e.send({
            eventName: r.CLOSE
          }), [2, new Promise(function (e) {
            setTimeout(function () {
              k(), e();
            }, s);
          })]) : (k(), [2, Promise.resolve()]);
        });
      });
    }();
  },
  getAppData: function () {
    return Ie(), function () {
      var e,
        t = J();
      try {
        e = t ? JSON.parse(t) : {};
      } catch (n) {
        e = {};
      }
      return Promise.resolve(e);
    }();
  }
};
export { Pe as getAppData, Oe as getMSTByMSIT, ue as getMainWindowOrigin, ie as getMessageBus, re as initMessageBus, oe as setMainWindowOrigin, Le as subWindow };