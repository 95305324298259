import { INVALID_CONFIG as n, STORE_KEY as t, STORE_OBJECT as i } from "@liff/consts";
import { isInClient as f } from "@liff/is-in-client";
import { createLiffError as r, cookie as o } from "@liff/util";
var u = {};
function e() {
  return u;
}
function c(n) {
  u = n;
}
function E(i, o) {
  if (!o) throw r(n, "liffId is necessary for liff.init()");
  var u = (f() ? sessionStorage : localStorage).getItem(t + ":" + o + ":" + i);
  try {
    return null === u ? null : JSON.parse(u);
  } catch (e) {
    return null;
  }
}
function I(n) {
  return E(n, e().liffId);
}
function l(i, o) {
  var u = e().liffId;
  if (!u) throw r(n, "liffId is necessary for liff.init()");
  (f() ? sessionStorage : localStorage).setItem(t + ":" + u + ":" + i, JSON.stringify(o));
}
function T() {
  return I(i.CONTEXT);
}
function S(n) {
  l(i.CONTEXT, n);
}
function s() {
  return ((T() || {}).d || {}).aId;
}
function a() {
  return ((T() || {}).d || {}).autoplay || !1;
}
function _() {
  return (T() || {}).profilePlus;
}
function N() {
  return Boolean(I(i.IS_SUBSEQUENT_LIFF_APP));
}
function O(n) {
  l(i.IS_SUBSEQUENT_LIFF_APP, n);
}
function A() {
  return I(i.APP_DATA);
}
function P(n) {
  l(i.APP_DATA, n);
}
function C() {
  return I(i.MST_VERIFIER);
}
function D(n) {
  l(i.MST_VERIFIER, n);
}
function d() {
  return I(i.MSIT);
}
function m(n) {
  l(i.MSIT, n);
}
function g() {
  return I(i.MST);
}
function L(n) {
  l(i.MST, n);
}
function M() {
  return I(i.MST_CHALLENGE);
}
function p(n) {
  l(i.MST_CHALLENGE, n);
}
function R() {
  return I(i.CLIENT_ID);
}
function v(n) {
  l(i.CLIENT_ID, n);
}
function F() {
  return I(i.RAW_CONTEXT);
}
function K() {
  return I(i.FEATURE_TOKEN);
}
function h(n) {
  l(i.FEATURE_TOKEN, n);
}
function y() {
  return I(i.ID_TOKEN);
}
function U(n) {
  l(i.ID_TOKEN, n);
}
function X() {
  return I(i.ACCESS_TOKEN);
}
function G(n) {
  l(i.ACCESS_TOKEN, n);
}
function w(i) {
  var o = e().liffId;
  if (!o) throw r(n, "liffId is necessary for liff.init()");
  (f() ? sessionStorage : localStorage).removeItem(t + ":" + o + ":" + i);
}
function B() {
  return I(i.LOGIN_TMP);
}
function x(n) {
  l(i.LOGIN_TMP, n);
}
function H() {
  w(i.LOGIN_TMP);
}
function J(n) {
  var f = e();
  o.set(t + ":" + i.EXPIRES + ":" + f.liffId, n.getTime(), {
    expires: n.toUTCString(),
    path: "/",
    secure: null
  });
}
function Q() {
  var n = e();
  return o.get(t + ":" + i.EXPIRES + ":" + n.liffId);
}
function V() {
  var n = e();
  o.remove(t + ":" + i.EXPIRES + ":" + n.liffId, {
    path: "/"
  });
}
function b() {
  return I(i.DECODED_ID_TOKEN);
}
function j(n) {
  l(i.DECODED_ID_TOKEN, n);
}
function k() {
  Object.keys(i).forEach(function (n) {
    w(i[n]);
  }), V();
}
export { k as clean, I as get, s as getAId, X as getAccessToken, A as getAppData, E as getByLiffId, R as getClientId, e as getConfig, T as getContext, b as getDecodedIDToken, Q as getExpireTime, K as getFeatureToken, y as getIDToken, N as getIsSubsequentLiffApp, a as getIsVideoAutoPlay, B as getLoginTmp, d as getMSIT, g as getMST, M as getMSTChallenge, C as getMSTVerifier, _ as getProfilePlus, F as getRawContext, w as remove, V as removeExpireTime, H as removeLoginTmp, l as set, G as setAccessToken, P as setAppData, v as setClientId, c as setConfig, S as setContext, j as setDecodedIDToken, J as setExpireTime, h as setFeatureToken, U as setIDToken, O as setIsSubsequentLiffApp, x as setLoginTmp, m as setMSIT, L as setMST, p as setMSTChallenge, D as setMSTVerifier };