import { __spread as o } from "tslib";
var t;
!function (o) {
  o[o.DEBUG = 1] = "DEBUG", o[o.INFO = 2] = "INFO", o[o.WARN = 3] = "WARN", o[o.ERROR = 4] = "ERROR";
}(t || (t = {}));
var n = function () {
    function n(o) {
      void 0 === o && (o = t.INFO), this.logLevel = o, this._debug = console.debug, this._info = console.info, this._warn = console.warn, this._error = console.error;
    }
    return n.prototype.debug = function () {
      for (var n = [], r = 0; r < arguments.length; r++) n[r] = arguments[r];
      this.logLevel <= t.DEBUG && (n.unshift("[DEBUG]"), this._debug.apply(this, o(n)));
    }, n.prototype.info = function () {
      for (var n = [], r = 0; r < arguments.length; r++) n[r] = arguments[r];
      this.logLevel <= t.INFO && (n.unshift("[INFO]"), this._info.apply(this, o(n)));
    }, n.prototype.warn = function () {
      for (var n = [], r = 0; r < arguments.length; r++) n[r] = arguments[r];
      this.logLevel <= t.WARN && (n.unshift("[WARN]"), this._warn.apply(this, o(n)));
    }, n.prototype.error = function () {
      for (var n = [], r = 0; r < arguments.length; r++) n[r] = arguments[r];
      this.logLevel <= t.ERROR && (n.unshift("[ERROR]"), this._error.apply(this, o(n)));
    }, n;
  }(),
  r = new n(Number("3"));
export { t as LogLevel, n as Logger, r as logger };