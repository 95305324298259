import { INVALID_ARGUMENT as r } from "@liff/consts";
import { createLiffError as e, isIpad as t } from "@liff/util";
import { fetch as o, getEndPoint as n } from "@liff/server-api";
import { getLineVersion as i } from "@liff/get-line-version";
import { getOS as f } from "@liff/get-os";
var s = function (r) {
  return "object" == typeof r && null !== r && function (r) {
    return "string" == typeof r || r instanceof String;
  }(r.type);
};
function a(t) {
  return Promise.reject(e(r, t));
}
function m(r) {
  if (!function (r) {
    return Array.isArray(r) && r.every(s);
  }(r)) return a("Parameter 'messages' must be an array of { type, ... }");
  var e = r.length;
  return e < 1 || e > 5 ? a("Number of messages should be in range 1 to 5.") : o(n("message"), {
    method: "POST",
    body: JSON.stringify({
      messages: r
    })
  }).catch(u);
}
var u = function (r) {
  if ("403" === r.code) {
    var e = "12.0.0" === i(),
      o = "ios" === f(),
      n = t();
    e && (o || n) && window.alert("LINEアプリをLINE 12.0.1以降にアップデートしてください。\nPlease update your LINE app to LINE 12.0.1 or later.");
  }
  throw r;
};
export { u as alertToPromptUpdate, m as sendMessages };