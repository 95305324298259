import { __awaiter as e, __generator as t, __values as n, __spread as r } from "tslib";
import { PERMISSION_NAMES as i, INVALID_ARGUMENT as o, UNAUTHORIZED as s, FORBIDDEN as a, UNKNOWN as u } from "@liff/consts";
import { createLiffError as c, extractChannelIdFromLiffId as f } from "@liff/util";
import { getContext as l, getAccessToken as h, getConfig as p, setAccessToken as d } from "@liff/store";
import { verifyAccessToken as v, fetch as m, getEndPoint as w } from "@liff/server-api";
import { isInClient as b } from "@liff/is-in-client";
import { subWindow as y } from "@liff/sub-window";
import { isApiAvailable as C } from "@liff/is-api-available";
function g(r) {
  return e(this, void 0, void 0, function () {
    var e, a, u, f, p, d, m;
    return t(this, function (t) {
      switch (t.label) {
        case 0:
          return function (e) {
            if (!i.includes(e)) throw c(o, "Unexpected permission name.");
            var t = l();
            return !!(null == t ? void 0 : t.scope.includes(e));
          }(r) ? (e = h()) ? [4, v(e)] : [3, 2] : [2, {
            state: "unavailable"
          }];
        case 1:
          a = t.sent(), u = unescape(a.scope).split(" ");
          try {
            for (f = n(u), p = f.next(); !p.done; p = f.next()) if (p.value.includes(r)) return [2, {
              state: "granted"
            }];
          } catch (w) {
            d = {
              error: w
            };
          } finally {
            try {
              p && !p.done && (m = f.return) && m.call(f);
            } finally {
              if (d) throw d.error;
            }
          }
          return [2, {
            state: "prompt"
          }];
        case 2:
          throw c(s, "LiffId is not found.");
      }
    });
  });
}
function I() {
  var e,
    t,
    n = l();
  return !!n && "square_chat" !== n.type && (C("skipChannelVerificationScreen") || !b() && (null === (t = null === (e = n.availability) || void 0 === e ? void 0 : e.skipChannelVerificationScreen) || void 0 === t ? void 0 : t.permission));
}
function k() {
  var e = p().liffId;
  if (e) return m(w("unauthorizedPermissions") + "?liffId=" + e, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + h()
    }
  });
  throw c(s, "liffId is required");
}
var j,
  A = y.on,
  S = y.off,
  T = y.open,
  q = function () {
    function n(n, r) {
      var i = this;
      this.onSubmit = function (n) {
        var r = n.newAccessToken,
          o = n.ICA_ERROR;
        return e(i, void 0, void 0, function () {
          return t(this, function (e) {
            return r ? this.resolve({
              newAccessToken: r
            }) : o && this.reject(c(u, o)), this.teardown(), [2];
          });
        });
      }, this.onClose = function () {
        return e(i, void 0, void 0, function () {
          return t(this, function (e) {
            return this.reject(c(s, "user didn't allow the agreement")), this.teardown(), [2];
          });
        });
      }, this.onCancel = function () {
        return e(i, void 0, void 0, function () {
          return t(this, function (e) {
            return this.reject(c(s, "user didn't allow the agreement")), this.teardown(), [2];
          });
        });
      }, this.onError = function (n) {
        return e(i, void 0, void 0, function () {
          return t(this, function (e) {
            return this.reject(n), this.teardown(), [2];
          });
        });
      }, this.resolve = n, this.reject = r, this.setup();
    }
    return n.prototype.setup = function () {
      A("submit", this.onSubmit), A("close", this.onClose), A("cancel", this.onCancel), A("error", this.onError);
    }, n.prototype.teardown = function () {
      S("submit", this.onSubmit), S("close", this.onClose), S("cancel", this.onCancel), S("error", this.onError), j = void 0;
    }, n.prototype.open = function () {
      var e = p().liffId;
      e ? T({
        url: "https://liff.line.me/1656032314-Xgrw5Pmk",
        appData: {
          liffId: e,
          channelId: f(e),
          accessToken: h()
        }
      }) : this.reject(c(s, "liffId is required"));
    }, n;
  }();
function x() {
  return e(this, void 0, void 0, function () {
    var e, n;
    return t(this, function (t) {
      switch (t.label) {
        case 0:
          if (!I()) throw c(a, "SkipChannelVerificationScreen is unavailable.");
          return j && j.teardown(), [4, k()];
        case 1:
          return e = t.sent(), (b() ? e : e.filter(function (e) {
            return "chat_message.write" !== e;
          })).length > 0 ? [4, new Promise(function (e, t) {
            (j = new q(e, t)).open();
          })] : [3, 3];
        case 2:
          return n = t.sent().newAccessToken, d(n), [3, 4];
        case 3:
          throw c(a, "All permissions have already been approved.");
        case 4:
          return [2];
      }
    });
  });
}
function E(n, i) {
  var o = this;
  return function () {
    for (var s = [], u = 0; u < arguments.length; u++) s[u] = arguments[u];
    return e(o, void 0, void 0, function () {
      var e, o, u;
      return t(this, function (t) {
        switch (t.label) {
          case 0:
            return e = (s.length > 0 ? s[s.length - 1] : {}).ignorePermissionCheck, o = void 0 !== e && e, [4, g(i)];
          case 1:
            if ("unavailable" !== (u = t.sent().state)) return [3, 2];
            throw c(a, "The permission is not in LIFF app scope.");
          case 2:
            return "prompt" !== u || !I() || o || !b() && "chat_message.write" === i ? [3, 4] : [4, x()];
          case 3:
            return t.sent(), [3, 5];
          case 4:
            o && s.pop(), t.label = 5;
          case 5:
            return [4, n.apply(void 0, r(s))];
          case 6:
            return [2, t.sent()];
        }
      });
    });
  };
}
var P = function () {
    function e() {
      this.name = "permission";
    }
    return e.prototype.install = function () {
      return {
        query: g,
        requestAll: x
      };
    }, e;
  }(),
  _ = new P();
export { P as PermissionModule, E as attachChecker, _ as module };