import { __read as t, __assign as e } from "tslib";
import { logger as i } from "@liff/logger";
var n = function (t, e) {
    this._driver = t, this.liff = e, this.hooks = this._driver.hooks, this.internalHooks = this._driver.internalHooks;
  },
  o = function (t, e) {
    this._driver = t, this.liff = e, this.hooks = this._driver.hooks;
  },
  r = function () {
    function i() {
      this.modules = new Map(), this.hooks = {}, this.internalHooks = {};
    }
    return i.prototype.addModule = function (i, n) {
      this.modules.set(i, n), n.hooks && (this.hooks[i] = Object.entries(n.hooks).reduce(function (i, n) {
        var o,
          r = t(n, 2),
          s = r[0],
          a = r[1];
        return e(e({}, i), ((o = {})[s] = a.on.bind(a), o));
      }, {})), n.internalHooks && (this.internalHooks[i] = Object.entries(n.internalHooks).reduce(function (i, n) {
        var o,
          r = t(n, 2),
          s = r[0],
          a = r[1];
        return e(e({}, i), ((o = {})[s] = a.on.bind(a), o));
      }, {}));
    }, i.prototype.hasModule = function (t) {
      return this.modules.has(t);
    }, i;
  }(),
  s = function () {
    function t(t, e, i) {
      this.driver = t, this.context = e, this.option = i;
    }
    return t.prototype.install = function () {
      return this.factory(this.driver, this.context);
    }, Object.defineProperty(t.prototype, "name", {
      get: function () {
        return "use";
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "defaultOption", {
      get: function () {
        return {
          namespacePrefix: "$"
        };
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.factory = function (t, e) {
      var n = Object.assign({}, this.defaultOption, this.option).namespacePrefix;
      return function (o, r) {
        if (!o || "function" != typeof o.install || "string" != typeof o.name) return i.warn("To install the plugin, you need to define the `name` property and the `install` method."), this;
        var s = "" + n + o.name;
        if (t.hasModule(s)) return this;
        var a = o.install.call(o, e, r);
        return this["" + s] ? (i.warn("There is a duplicate plugin name. `" + s + "` plugin namespace will be override."), this["" + s] = a) : void 0 !== a && (this["" + s] = a), t.addModule(s, o), this;
      };
    }, t;
  }();
export { o as LiffPluginContextImpl, n as ModuleContextImpl, r as ModuleDriverImpl, s as UseModule };