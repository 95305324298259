import { __read as i } from "tslib";
import { INVALID_ARGUMENT as n } from "@liff/consts";
import { createLiffError as o, compareVersion as e } from "@liff/util";
import { isInClient as f } from "@liff/is-in-client";
import { getOS as r } from "@liff/get-os";
import { getLineVersion as t } from "@liff/get-line-version";
import { call as l } from "@liff/native-bridge";
var p = function (i, n) {
  return i ? "&" + i.split("&").filter(function (i) {
    return -1 === i.indexOf("is_liff_external_open_window");
  }).join("&").concat(n ? "#" + n : "") : n ? "#" + n : "";
};
function d(d) {
  if (!function (n) {
    if (!n || "object" != typeof n) return !1;
    var o = n,
      e = o.url,
      f = o.external,
      r = i([typeof e, typeof f], 2),
      t = r[0],
      l = r[1];
    return "string" === t && "" !== e && ("undefined" === l || "boolean" === l);
  }(d)) throw o(n, "Invalid parameters for liff.openWindow()");
  var s = t();
  if (f()) {
    if (null !== s && "ios" === r() && e(s, "9.19") >= 0 || !window._liff.postMessage) {
      var u = d.url,
        a = d.external,
        m = void 0 !== a && a;
      window.open(function (n, o) {
        var e, f, r, t, l, d, s, u, a;
        return function (i) {
          return -1 !== i.indexOf("#") && -1 !== i.indexOf("?") && i.indexOf("#") < i.indexOf("?");
        }(n) || function (i) {
          return -1 === i.indexOf("?") && -1 !== i.indexOf("#");
        }(n) ? (s = (e = i(n.split("#"), 2))[0], f = e[1], u = (r = i((void 0 === f ? "" : f).split("?"), 2))[0], a = r[1]) : (s = (t = i(n.split("?"), 2))[0], l = t[1], a = (d = i((void 0 === l ? "" : l).split("#"), 2))[0], u = d[1]), s + "?is_liff_external_open_window=" + !!o + p(a, u);
      }(u, m));
    } else l("openWindow", d);
  } else window.open(d.url, "_blank");
}
export { d as openWindow };