import { __awaiter as o, __generator as t } from "tslib";
import { FORBIDDEN as n } from "@liff/consts";
import { createLiffError as e } from "@liff/util";
import { subWindow as r } from "@liff/sub-window";
import { isApiAvailable as i } from "@liff/is-api-available";
var s,
  f = function () {
    function o(o, t) {
      var n = this;
      this.resolve = o, this.reject = t, this.onSubmit = function (o) {
        var t = o.message;
        n.resolve({
          value: t
        }), n.destroy();
      }, this.onClose = function () {
        n.resolve({
          value: null
        }), n.destroy();
      }, this.onCancel = function () {
        n.resolve({
          value: null
        }), n.destroy();
      }, this.onError = function (o) {
        n.reject(o), n.destroy();
      }, this.start();
    }
    return o.prototype.start = function () {
      r.on("submit", this.onSubmit), r.on("close", this.onClose), r.on("cancel", this.onCancel), r.on("error", this.onError);
    }, o.prototype.destroy = function () {
      r.off("submit", this.onSubmit), r.off("close", this.onClose), r.off("cancel", this.onCancel), r.off("error", this.onError), s = void 0;
    }, o;
  }();
function u() {
  return o(this, void 0, void 0, function () {
    return t(this, function (o) {
      if (!i("subwindowOpen") || !i("scanCodeV2")) throw e(n, "No permission for liff.scanCodeV2()");
      return s && s.destroy(), [2, new Promise(function (o, t) {
        s = new f(o, t), r.open({
          url: "https://liff.line.me/1656359117-jxmx5e11"
        }).catch(function (o) {
          null == s || s.destroy(), t(o);
        });
      })];
    });
  });
}
var l = function () {
    function o() {}
    return Object.defineProperty(o.prototype, "name", {
      get: function () {
        return "scanCodeV2";
      },
      enumerable: !1,
      configurable: !0
    }), o.prototype.install = function () {
      return u;
    }, o;
  }(),
  c = new l();
export { l as ScanCodeV2Module, c as module };