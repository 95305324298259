import { __awaiter as t, __generator as e, __assign as i } from "tslib";
import { UTS_REFERRER_QUERY as s } from "@liff/consts";
import { logger as r } from "@liff/logger";
import { removeCredential as n, qs as o } from "@liff/util";
import { getDecodedIDToken as f, getContext as u, getConfig as a } from "@liff/store";
import { getVersion as c } from "@liff/get-version";
import { isLoggedIn as l } from "@liff/is-logged-in";
import { getProfile as d } from "@liff/get-profile";
function p() {
  return t(this, void 0, void 0, function () {
    var t, i;
    return e(this, function (e) {
      switch (e.label) {
        case 0:
          if (!l()) return [3, 6];
          e.label = 1;
        case 1:
          return e.trys.push([1, 5,, 6]), (t = f()) && t.sub ? [2, t.sub] : [3, 2];
        case 2:
          return [4, d()];
        case 3:
          if ((i = e.sent()) && i.userId) return [2, i.userId];
          e.label = 4;
        case 4:
          return [3, 6];
        case 5:
          return e.sent(), r.debug("can't retrieve Mid/Uid because of something wrong"), [3, 6];
        case 6:
          return [2];
      }
    });
  });
}
function h() {
  return t(this, void 0, void 0, function () {
    var t;
    return e(this, function (e) {
      switch (e.label) {
        case 0:
          return [4, p()];
        case 1:
          return (t = e.sent()) && "u" === t.substring(0, 1) ? [2, t] : [2];
      }
    });
  });
}
var g = function () {
    function f() {
      this.utsExtra = {
        isLiffSuccessful: !1,
        isLoggedIn: !1,
        id: "",
        version: ""
      }, this.injected = !1;
    }
    return Object.defineProperty(f, "CUSTOMPLACEID_INIT", {
      get: function () {
        return "liff.init";
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(f, "CUSTOMTYPE", {
      get: function () {
        return "liffSdk";
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(f, "GENERAL_UTS_ID", {
      get: function () {
        return "liff_general";
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(f, "GENERAL_APP_NAME", {
      get: function () {
        return "LIFF General";
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(f, "LiffUtsLoginStatus", {
      get: function () {
        return {
          isLoggedIn: 1,
          isLiffSuccessful: 2
        };
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(f.prototype, "name", {
      get: function () {
        return "analytics";
      },
      enumerable: !1,
      configurable: !0
    }), f.prototype.install = function (t) {
      var e = t.liff,
        i = t.internalHooks;
      this.liffCore = e, i.init.beforeFinished(this.beforeInitFinished.bind(this)), i.init.beforeSuccess(this.beforeInitSuccess.bind(this)), i.init.error(this.initError.bind(this));
    }, f.prototype.changeRatioToUTSFormat = function (t) {
      if (t && Number.isFinite(t)) return Math.round(100 * t);
    }, f.prototype.setExtra = function () {
      var t = this.utsExtra,
        e = t.isLiffSuccessful,
        i = t.isLoggedIn,
        s = t.id,
        r = t.version,
        n = (i ? f.LiffUtsLoginStatus.isLoggedIn : 0) | (e ? f.LiffUtsLoginStatus.isLiffSuccessful : 0);
      this.uts.setExtra("liff", {
        id: s,
        loginStatus: n,
        version: r
      });
    }, f.prototype.assignUtsExtra = function (t) {
      Object.assign(this.utsExtra, t);
    }, f.prototype.setVersion = function (t) {
      this.assignUtsExtra({
        version: t
      }), r.debug("[LIFFUTS][SDK version] " + t), this.setExtra();
    }, f.prototype.setLiffId = function (t) {
      this.assignUtsExtra({
        id: t
      }), r.debug("[LIFFUTS][LIFFID] " + t), this.setExtra();
    }, f.prototype.setIsLoggedIn = function (t) {
      this.assignUtsExtra({
        isLoggedIn: t
      }), r.debug("[LIFFUTS][isLoggedIn] " + t), this.setExtra();
    }, f.prototype.sendLiffInit = function () {
      r.debug("[LIFFUTS][sendCustom] liff.init"), this.uts.sendCustom({
        type: f.CUSTOMTYPE,
        params: {
          placeId: f.CUSTOMPLACEID_INIT
        }
      });
    }, f.prototype.setIsLiffSuccessful = function (t) {
      this.assignUtsExtra({
        isLiffSuccessful: t
      }), r.debug("[LIFFUTS][isLiffInitSuccessful] " + t), this.setExtra();
    }, f.prototype.prepareReferrer = function (t) {
      var e = {};
      Object.keys(t).forEach(function (i) {
        if (s.includes(i)) {
          var r = t[i];
          "string" == typeof r && r && (e[i.replace(/^liff\.ref\./, "")] = r);
        }
      }), Object.keys(e).length > 0 && (this.referrer = e);
    }, f.prototype.beforeInitFinished = function () {
      return t(this, void 0, void 0, function () {
        var t, s, d, p, g, b, I, m, L, S, E, v, T;
        return e(this, function (e) {
          switch (e.label) {
            case 0:
              if (t = o.parse(window.location.search), this.prepareReferrer(t), s = u(), !(d = null == s ? void 0 : s.utsTracking)) return [2];
              if ("auto" !== (p = d.mode) && "force" !== p) return [3, 6];
              r.debug("[LIFFUTS] " + new Date().toUTCString()), g = a(), b = g.liffId, I = g.analytics, e.label = 1;
            case 1:
              return e.trys.push([1, 3,, 4]), m = this, [4, new Promise(function (t, e) {
                var i = window.uts,
                  s = document.createElement("script");
                s.type = "text/javascript", s.src = "https://static.line-scdn.net/uts/edge/4.1.0/uts.js", s.onload = function () {
                  var e = window.uts;
                  t(e), window.uts = i;
                }, s.onerror = function (t) {
                  e(t);
                }, document.getElementsByTagName("head")[0].appendChild(s);
              })];
            case 2:
              return m.uts = e.sent(), [3, 4];
            case 3:
              return L = e.sent(), r.debug("[LIFFUTS] cannot load UTS, reason: " + L), [2];
            case 4:
              return S = void 0, E = void 0, "force" === p ? (S = {
                utsId: f.GENERAL_UTS_ID,
                appName: f.GENERAL_APP_NAME,
                appEnv: "release"
              }, E = {
                endpoint: "https://uts-front.line-apps.com",
                sampleRate: this.changeRatioToUTSFormat(d.sendRatio),
                version: "current"
              }) : (S = i(i({}, null == I ? void 0 : I.context), {
                utsId: (null == I ? void 0 : I.context.utsId) || f.GENERAL_UTS_ID,
                appName: (null == I ? void 0 : I.context.appName) || f.GENERAL_APP_NAME,
                appEnv: (null == I ? void 0 : I.context.appEnv) || "release"
              }), E = i(i({
                endpoint: "https://uts-front.line-apps.com"
              }, null == I ? void 0 : I.options), {
                sampleRate: this.changeRatioToUTSFormat(d.sendRatio),
                version: "current"
              })), this.uts.init(S, E), [4, h()];
            case 5:
              (v = e.sent()) && (r.debug("[LIFFUTS][mid] " + v), this.uts.setMid(v)), (null == s ? void 0 : s.tid) && (r.debug("[LIFFUTS][tid] " + s.tid), this.uts.setTid(s.tid)), this.referrer && (r.debug("liff.ref.referrer", this.referrer), this.uts.setSessionParams(this.referrer)), b && this.setLiffId(b), this.setIsLoggedIn(l()), this.setVersion(c()), T = n(location.href), r.debug("[LIFFUTS][url] " + T), this.uts.setUrl(T), this.liffCore.analytics = this.uts, this.injected = !0, e.label = 6;
            case 6:
              return [2];
          }
        });
      });
    }, f.prototype.beforeInitSuccess = function () {
      return this.injected && (this.setIsLiffSuccessful(!0), this.sendLiffInit()), Promise.resolve();
    }, f.prototype.initError = function () {
      return this.injected && (this.setIsLiffSuccessful(!1), this.sendLiffInit()), Promise.resolve();
    }, f;
  }(),
  b = function (t) {
    r.debug("[LIFFUTS][sendCustom] liff.shareTargetPicker"), t.sendCustom({
      type: "liffSdk",
      params: {
        placeId: "liff.shareTargetPicker"
      }
    });
  };
export { g as AnalyticsModule, b as sendShareTargetPicker };