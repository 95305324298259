import { INVALID_ARGUMENT as n } from "@liff/consts";
import { createLiffError as r, compareVersion as i } from "@liff/util";
import { getContext as e } from "@liff/store";
import { isInClient as t } from "@liff/is-in-client";
import { isLoggedIn as o } from "@liff/is-logged-in";
import { getLineVersion as u } from "@liff/get-line-version";
var f = ["subwindowOpen", "shareTargetPicker", "multipleLiffTransition", "scanCode", "scanCodeV2", "getAdvertisingId", "addToHomeScreen", "bluetoothLeFunction", "skipChannelVerificationScreen"];
function a(n) {
  var r,
    i = e();
  return null === (r = null == i ? void 0 : i.availability) || void 0 === r ? void 0 : r[n];
}
function s(n, r) {
  var e = u();
  return !!e && !(r && i(e, r) > 0) && i(e, n) >= 0;
}
function c(n) {
  var r = a(n);
  if (!r) return !1;
  var i = r.permission,
    e = r.minVer,
    o = r.unsupportedFromVer;
  return !!i && !!t() && s(e, o);
}
var l = {
  shareTargetPicker: function () {
    if (!o()) return !1;
    var n = a("shareTargetPicker");
    if (!n) return !1;
    var r = n.permission,
      e = n.minVer;
    if (!r) return !1;
    if (t()) {
      var f = u();
      return null !== f && i(f, e) >= 0;
    }
    return !0;
  },
  multipleLiffTransition: function () {
    var n = a("multipleLiffTransition");
    if (!n) return !1;
    var r = n.permission;
    return t() && r;
  },
  subwindowOpen: function () {
    var n = a("subwindowOpen");
    return !(!n || !n.permission) && (!t() || s(n.minVer, n.unsupportedFromVer));
  },
  scanCode: function () {
    return c("scanCode");
  },
  scanCodeV2: function () {
    if (!o()) return !1;
    var n = a("scanCodeV2");
    return !(!n || !n.permission) && (!t() || s(n.minVer));
  },
  getAdvertisingId: function () {
    return c("getAdvertisingId");
  },
  addToHomeScreen: function () {
    return c("addToHomeScreen");
  },
  bluetoothLeFunction: function () {
    return c("bluetoothLeFunction");
  },
  skipChannelVerificationScreen: function () {
    return c("skipChannelVerificationScreen");
  }
};
function m(i) {
  if (!f.includes(i)) throw r(n, "Unexpected API name.");
  var e = l[i];
  return !e || e();
}
export { m as isApiAvailable };