import { LIFF_EVENT as t, INVALID_ARGUMENT as e, FORBIDDEN as i } from "@liff/consts";
import { logger as o } from "@liff/logger";
import { createLiffError as n, randomAlphaNumericString as a } from "@liff/util";
import { getFeatureToken as r } from "@liff/store";
import { __assign as l } from "tslib";
function d(e) {
  return new CustomEvent(t, {
    detail: e
  });
}
!function () {
  if ("function" != typeof window.CustomEvent) {
    function t(t, e) {
      var i = e || {},
        o = i.bubbles,
        n = void 0 !== o && o,
        a = i.cancelable,
        r = void 0 !== a && a,
        l = i.detail,
        d = void 0 === l ? void 0 : l,
        f = document.createEvent("CustomEvent");
      return f.initCustomEvent(t, n, r, d), f;
    }
    t.prototype = Event.prototype, window.CustomEvent = t;
  }
}();
var f = {},
  c = !1;
function s(e, i) {
  c || (c = !0, window.addEventListener(t, function (t) {
    t && t.detail && t.detail.type && f[t.detail.type] && f[t.detail.type].forEach(function (e) {
      return e(t);
    });
  })), f[e] ? f[e].push(i) : f[e] = [i];
}
function u(t, e) {
  var i = f[t];
  if (i && Array.isArray(i)) {
    var o = i.indexOf(e);
    o >= 0 && i.splice(o, 1);
  }
}
function v(t) {
  var i = {};
  try {
    i = JSON.parse(t);
  } catch (r) {
    throw n(e, r.message);
  }
  var a = d(i);
  o.debug("[client dispatchEvent to js]", {
    type: a.type,
    detail: a.detail
  }), window.dispatchEvent(a);
}
function p(t, a, l) {
  void 0 === a && (a = {}), void 0 === l && (l = "");
  var d = r();
  if (!d) throw n(i, "Invalid featureToken for client features");
  if (!window._liff || !window._liff.postMessage) throw n(e, "postMessage is not available from client");
  o.debug("[js postMessage to client]", t, l, a), window._liff.postMessage(t, d, l, JSON.stringify(a));
}
function m(t, e, d) {
  return void 0 === e && (e = {}), void 0 === d && (d = {
    once: !0
  }), r() ? (d = l({
    callbackId: a(12),
    once: !0
  }, d), new Promise(function (i, n) {
    var a = function (e) {
      if (e && e.detail) {
        var r = e.detail.callbackId === d.callbackId,
          l = "string" != typeof e.detail.callbackId;
        (r || l) && (d.once && u(t, a), o.debug("[callback detail]", e.detail), e.detail.error ? n(e.detail.error) : e.detail.data ? i(e.detail.data) : n(e.detail));
      }
      n();
    };
    s(t, a), p(t, e, d.callbackId);
  })) : Promise.reject(n(i, "Invalid featureToken for client features"));
}
export { s as addListener, m as call, d as createEvent, v as dispatch, p as postMessage, u as removeListener };